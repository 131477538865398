import React from 'react';
import AccountDetails from '../components/AccountDetails';
import { Hourglass } from 'react-loader-spinner';
import { useSelector } from 'react-redux';


const AccountPage = () => {
    const loader = useSelector((state) => state.auth.loading)
    
    

    return (
        <div className='h-full'>
            <AccountDetails/>
            {/* {loader ? (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <Hourglass
                        visible={true}
                        height="40"
                        width="40"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#306cce', '#72a1ed']}
                    />
                </div>) : null} */}
        </div>
    );
}

export default AccountPage;
