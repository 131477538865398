import React, { useEffect, useRef, useState } from 'react';
import logo from '../assets/logo.png'
import { IoEyeOutline } from 'react-icons/io5';
import { FaRegEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Hourglass } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { setAuthData } from '../Redux/AuthSlice';
import { api_url } from '../config';
import { countries } from '../countries';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const SignupForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordState, setPasswordState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [city, setCity] = useState('');
    const [town, setTown] = useState('');
    const [country, setCountry] = useState('');
    const [address, setAddress] = useState('');
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
    const [isValidPhone,setValidPhone] = useState(true)
    const [companyType, setCompanyType] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companySite, setCompanySite] = useState('');
    const [adminName, setAdminName] = useState('');
    const [errorSubmission, setErrorSubmission] = useState(false)
    const [confirm_passwordSate, setConfirm_passwordState] = useState(false)
    const [confirm_password, setConfirm_password] = useState('')
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [countryInput, setCountryInput] = useState('');
    const [cityInput, setCityInput] = useState('');
    const [countrySuggestions, setCountrySuggestions] = useState([]);
    const [citySuggestions, setCitySuggestions] = useState([]);
    const [showCountrySuggestions, setShowCountrySuggestions] = useState(false);
    const [showCitySuggestions, setShowCitySuggestions] = useState(false);
    const countryInputRef = useRef(null);
    const cityInputRef = useRef(null);


    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handlePhoneNumberChange = (value) =>{
        setCompanyPhoneNumber(value)
        if(value.length > 15){
            setValidPhone(false)
        }
    }
    const handlePasswordToggle = (e) => {
        e.preventDefault();
        setPasswordState(!passwordState);
    };
    const handleConfirmPasswordToggle = (e) => {
        e.preventDefault();
        setConfirm_passwordState(!confirm_passwordSate)
    }

    const handleSignUp = async (e) => {
        e.preventDefault()
        const form = {
            email,
            password,
            password_confirmation: confirm_password,
            zip_code: zipCode,
            city:cityInput,
            town,
            country:countryInput,
            address,
            company_name: companyName,
            company_phone: companyPhoneNumber,
            company_domain: companySite,
            company_type: companyType,
            name: adminName,
        }
        //console.log(form);
        try {
            setLoading(true);
            const response = await axios.post(api_url + '/register', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            console.log(response.data);
            dispatch(setAuthData(response.data))
            navigate('/subscriptions')
        } catch (error) {
            console.log(error);
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError(null);
            }, 6000);

        } finally {
            setLoading(false);
        }
    }

    const handleCountryInputChange = (e) => {
        const value = e.target.value;
        setCountryInput(value);
        // Filter countries based on the input value
        const filteredSuggestions = countries
            .filter(item => item.country.toLowerCase().includes(value.toLowerCase()));
        setCountrySuggestions(filteredSuggestions);
        setShowCountrySuggestions(true);
    };

    // Function to handle changes in the city input field
    const handleCityInputChange = (e) => {
        const value = e.target.value;
        setCityInput(value);
        // Filter cities based on the input value and selected country
        const selectedCountryCities = countries
            .find(item => item.country.toLowerCase() === countryInput.toLowerCase())?.cities || [];
        const filteredSuggestions = selectedCountryCities
            .filter(city => city.toLowerCase().includes(value.toLowerCase()));
        setCitySuggestions(filteredSuggestions);
        setShowCitySuggestions(true);
    };

    // Function to handle selection from country dropdown
    const handleSelectCountry = (selectedCountry) => {
        setCountryInput(selectedCountry);
        // Clear city input and suggestions when country changes
        setCityInput('');
        setCitySuggestions([]);
        setShowCountrySuggestions(false);
    };

    // Function to handle selection from city dropdown
    const handleSelectCity = (selectedCity) => {
        setCityInput(selectedCity);
        setCitySuggestions([]);
        setShowCitySuggestions(false);
    };

    // Close suggestions dropdowns when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryInputRef.current && !countryInputRef.current.contains(event.target)) {
                setShowCountrySuggestions(false);
            }
            if (cityInputRef.current && !cityInputRef.current.contains(event.target)) {
                setShowCitySuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const validateNumber = (number) =>{
        const pattern = /^\d{10}$/;
        return pattern.test(number)
    }


    return (
        <div className="flex items-center justify-center py-4 w-full">
            <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-lg md:max-w-3xl xl:p-0">
                <div className="p-6 space-y-4 md:space-y-4 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight font-lufga tracking-tight text-gray-900 md:text-2xl ">
                        Sign Up
                    </h1>
                    <form>
                        <div className="grid gap-4 mb-6 md:grid-cols-2">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga ">Admin Full Name</label>
                                <input
                                    type="text"
                                    value={adminName}
                                    onChange={(e) => { setAdminName(e.target.value) }}
                                    className={`bg-gray-50 border focus:outline-none  text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                                    placeholder=""
                                    required

                                />

                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga">Email</label>
                                <input
                                    type="text"
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    className={`bg-gray-50 border focus:outline-none  text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                                    placeholder=""
                                    required
                                />

                            </div>

                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga ">Company Name</label>
                                <input
                                    type="text"
                                    value={companyName}
                                    onChange={(e) => { setCompanyName(e.target.value) }}
                                    className={`bg-gray-50 border focus:outline-none  text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                                    placeholder=""
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga ">Company Mobile Number</label>
                                <PhoneInput
                                    type="text"
                                    country={'gh'}
                                    value={companyPhoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    // className={`bg-gray-50 border focus:outline-none  text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                                    placeholder=""
                                    required
                                />
                                {!isValidPhone &&   <p className='text-[8px] text-red-500'>Number should not be more than 15 digits</p> }
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga">Company Type</label>
                                <select
                                    value={companyType}
                                    onChange={(e) => setCompanyType(e.target.value)}
                                    className="bg-gray-50 border focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                    required
                                >
                                    <option value="" disabled>Select Company Type</option>
                                    <option value="hospital">Hospital</option>
                                    <option value="pharmacy">Pharmacy</option>
                                    <option value="insurance provider">Insurance Provider</option>
                                    <option value="eye clinic">Eye clinic</option>
                                    <option value="medical clinic">Medical clinic</option>
                                    <option value="other">other</option>
                                </select>
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga ">Company Website</label>
                                <input
                                    type="text"
                                    value={companySite}
                                    onChange={(e) => { setCompanySite(e.target.value) }}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 "
                                    placeholder=""
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga ">Address</label>
                                <input
                                    type="text"
                                    value={address}
                                    onChange={(e) => { setAddress(e.target.value) }}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5"
                                    placeholder=""
                                    required
                                />
                            </div>

                            <div ref={countryInputRef} className="relative">
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga">Country</label>
                                <input
                                    type="text"
                                    value={countryInput}
                                    onChange={handleCountryInputChange}
                                    onFocus={() => setShowCountrySuggestions(true)}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5"
                                    placeholder="Type country..."
                                    required
                                />
                                {/* Dropdown for country suggestions */}
                                {showCountrySuggestions && (
                                    <div className="absolute z-10 w-full bg-white border max-h-[300px] overflow-auto border-gray-300 rounded-lg shadow-lg mt-1">
                                        {countrySuggestions.map((country, index) => (
                                            <div
                                                key={index}
                                                className="py-1 px-2 hover:bg-gray-100 cursor-pointer"
                                                onClick={() => handleSelectCountry(country.country)}
                                            >
                                                {country.country}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div ref={cityInputRef} className="relative">
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga">City</label>
                                <input
                                    type="text"
                                    value={cityInput}
                                    onChange={handleCityInputChange}
                                    onFocus={() => setShowCitySuggestions(true)}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5"
                                    placeholder="Type city..."
                                    required
                                />
                                {/* Dropdown for city suggestions */}
                                {showCitySuggestions && (
                                    <div className="absolute z-10 w-full bg-white max-h-[300px] overflow-auto border border-gray-300 rounded-lg shadow-lg mt-1">
                                        {citySuggestions.map((city, index) => (
                                            <div
                                                key={index}
                                                className="py-1 px-2 hover:bg-gray-100 cursor-pointer"
                                                onClick={() => handleSelectCity(city)}
                                            >
                                                {city}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga ">Town</label>
                                <input
                                    type="text"
                                    value={town}
                                    onChange={(e) => setTown(e.target.value)}
                                    className={`bg-gray-50 border focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                                    placeholder=""
                                    required

                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga ">Zip Code</label>
                                <input
                                    type="text"
                                    value={zipCode}
                                    onChange={(e) => { setZipCode(e.target.value) }}
                                    className={`bg-gray-50 border focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                                    placeholder=""

                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga ">Password</label>
                                <div className={`bg-gray-50 border focus:outline-none  text-gray-900 text-sm rounded-lg flex justify-between items-center w-full `}>
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={passwordState ? 'text' : 'password'}
                                        name='password'
                                        placeholder='••••••••'
                                        className='focus:outline-none p-2.5 rounded-lg w-full bg-gray-50'
                                        required
                                    />
                                    {passwordState ? (
                                        <IoEyeOutline size={20} onClick={handlePasswordToggle} />
                                    ) : (
                                        <FaRegEyeSlash size={20} onClick={handlePasswordToggle} />
                                    )}
                                </div>
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 font-lufga">Confirm Password</label>
                                <div className={`bg-gray-50 border focus:outline-none  text-gray-900 text-sm rounded-lg flex justify-between items-center w-full `}>
                                    <input
                                        value={confirm_password}
                                        onChange={(e) => setConfirm_password(e.target.value)}
                                        type={confirm_passwordSate ? 'text' : 'password'}
                                        name='password'
                                        placeholder='••••••••'
                                        className='focus:outline-none p-2.5 rounded-lg w-full bg-gray-50'
                                        required
                                    />
                                    {confirm_passwordSate ? <IoEyeOutline size={20} onClick={handleConfirmPasswordToggle} /> : <FaRegEyeSlash size={20} onClick={handleConfirmPasswordToggle} />}

                                </div>
                            </div>


                        </div>

                        <div className='flex items-center gap-16'>
                            <div className='flex items-center gap-4'>
                                <button
                                    onClick={handleSignUp}
                                    type="submit"
                                    className="text-white bg-gradient-to-r font-lufga from-blue-400 to-blue-700 hover:opacity-90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    Submit
                                </button>
                                <Link to='/login'>
                                    <p className='hover:underline font-lufga'>Go back</p>
                                </Link>
                            </div>

                            <div>
                                {error && (
                                    <div>
                                        <p className="bg-red-300 rounded-md shadow-md p-3 my-2 font-lufga">
                                            {typeof error === 'string' ? error : (
                                                Object.keys(error).map((key) => (
                                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                                ))
                                            )}
                                        </p>
                                    </div>
                                )}
                            </div>

                        </div>
                        {loading ? (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                            <Hourglass
                                visible={true}
                                height="40"
                                width="40"
                                ariaLabel="hourglass-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                colors={['#306cce', '#72a1ed']}
                            />
                        </div>) : null}

                    </form>
                </div>
            </div>
        </div>
    );
}

export default SignupForm;
