import React from 'react';
import ResetPassword from '../components/ResetPassword';

const ResetPasswordPage = () => {
    return (
        <div className=' flex justify-center items-center h-screen '>
            <ResetPassword/>
        </div>
    );
}

export default ResetPasswordPage;
