import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../utils/api";
import { api_url } from "../config";


export const fetchSubscribedPlans = createAsyncThunk('fetchSubscribedPlans', async ({ userId, token }, { rejectWithValue }) => {
    // const response = await api.get('')
    // return response.data

    try {
        const response = await axios.get(api_url + `/v1/user/${userId}/subscriptions`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        return response?.data?.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || 'An error occurred');
    }
})


const subscribedPlansSlice = createSlice({
    name: 'subscribedPlans',
    initialState: {
        isLoading: false,
        data: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSubscribedPlans.pending, (state, action) => {
            state.isLoading = true;
            state.error = null; 
        });
        builder.addCase(fetchSubscribedPlans.fulfilled, (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
            state.error = null;
        });
        builder.addCase(fetchSubscribedPlans.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
    },

})

export default subscribedPlansSlice.reducer