import React, { useEffect, useState } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { FaRegEyeSlash } from 'react-icons/fa';
import { Hourglass, InfinitySpin } from 'react-loader-spinner';
import { Link, useSearchParams, useParams } from 'react-router-dom';
import axios from 'axios';
import { api_url } from '../config';
import { IoMdClose } from 'react-icons/io';
import { useSelector } from 'react-redux';

const UpdatePassword = ({ toggle }) => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState('')
    const [selectedOption, setSelectedOption] = useState(null);
    const [status, setStatus] = useState('')
    const [passwordState, setPasswordState] = useState(false);
    const [currentPasswordState, setCurrentPasswordState] = useState(false);
    const [confirm_passwordState, setConfirm_passwordState] = useState(false)
    const [password, setPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [email, setEmail] = useState('')
    const [searchParams] = useSearchParams()

    useEffect(() => {
        setEmail(searchParams.get("email"))
    }, [])

    const handlePasswordToggle = (e) => {
        e.preventDefault();
        setPasswordState(!passwordState);
    };
    const handleConfirmPasswordToggle = (e) => {
        e.preventDefault();
        setConfirm_passwordState(!confirm_passwordState)
    }
    const handleCurrentPasswordToggle = (e) => {
        e.preventDefault();
        setCurrentPasswordState(!currentPasswordState)
    }

    const {id,token} = useSelector((state)=>state.auth)

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            setLoading(true);
            const response = await axios.put(api_url + `/v1/user/${id}/profile/update-password`, {
              old_password:currentPassword,
                password,
                password_confirmation
            },{
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status == 200) {
                setStatus('Password reset successful')
                setTimeout(() => {
                    setStatus('');
                    toggle()
                }, 1500);

            }

          
        } catch (error) {
            console.log(error);
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 3000);
        } finally {
            setPassword('')
            setPassword_confirmation('')
            setLoading(false);
        }
    }
    return (
        <div className=' font-lufga m-2 shadow px-6 pt-4 pb-6 rounded-md w-full sm:w-[50vw] xl:w-[30vw] bg-white'>
            <IoMdClose
                onClick={() => { toggle() }}
                size={24} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />

            <form className='space-y-4 md:space-y-6' >
                <div>
                    <p className='text-xl font-lufga font-medium text-neutral-600 text-center mb-4'>Change your password</p>
                </div>
                <div>
                    <label
                        htmlFor='password'
                        className='block mb-2 text-sm font-medium text-gray-900 '
                    >
                        Current Password
                    </label>
                    <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'>
                        <input
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            type={currentPasswordState ? 'text' : 'password'}
                            name='password'
                            placeholder='••••••••'
                            className='bg-gray-50 focus:outline-none w-full'
                            required
                        />
                        {currentPasswordState ? (
                            <IoEyeOutline size={20} onClick={handleCurrentPasswordToggle} />
                        ) : (
                            <FaRegEyeSlash size={20} onClick={handleCurrentPasswordToggle} />
                        )}
                    </div>
                </div>
                <div>
                    <label
                        htmlFor='password'
                        className='block mb-2 text-sm font-medium text-gray-900 '
                    >
                        New Password
                    </label>
                    <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'>
                        <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={passwordState ? 'text' : 'password'}
                            name='password'
                            placeholder='••••••••'
                            className='bg-gray-50 focus:outline-none w-full'
                            required
                        />
                        {passwordState ? (
                            <IoEyeOutline size={20} onClick={handlePasswordToggle} />
                        ) : (
                            <FaRegEyeSlash size={20} onClick={handlePasswordToggle} />
                        )}
                    </div>
                </div>
                <div>
                    <label
                        className='block mb-2 font-lufga text-sm font-medium text-gray-900 '
                    >
                        Confirm New Password
                    </label>
                    <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'>
                        <input
                            value={password_confirmation}
                            onChange={(e) => setPassword_confirmation(e.target.value)}
                            type={confirm_passwordState ? 'text' : 'password'}
                            name='confirm_password'
                            placeholder='••••••••'
                            className='bg-gray-50 focus:outline-none w-full'
                            required
                        />
                        {confirm_passwordState ? (
                            <IoEyeOutline size={20} onClick={handleConfirmPasswordToggle} />
                        ) : (
                            <FaRegEyeSlash size={20} onClick={handleConfirmPasswordToggle} />
                        )}
                    </div>
                </div>
                {status ? <div>
                    <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{status}</p>
                </div> : error ? <div>
                    <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                        {typeof error === 'string' ? error : (
                            Object.keys(error).map((key) => (
                                Array.isArray(error[key]) ? error[key][0] : error[key]
                            ))
                        )}
                    </p>
                </div> : null}


                <div className='flex justify-end'>
                    <button

                        onClick={handleSubmit}
                        className=' text-white font-lufga bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center '
                    >

                        Save
                    </button>
                </div>
                {loading ? (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <Hourglass color="#387ADF" loading={loading} size={32} />
                </div>) : null}
                {/* <div className='flex justify-between'>
                        <Link to='/login'>
                            <p className='hover:underline cursor-pointer font-lufga text-sm text-blue-800'>Go to login</p>
                        </Link>

                    </div> */}
            </form>
        </div>
    );
}

export default UpdatePassword;
