import axios from 'axios';
import store from '../Store'; 
import { clearAuthData } from '../Redux/AuthSlice';
import { toast } from 'react-toastify';

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data.message === "unAuthenticated") {
      store.dispatch(clearAuthData());
      
    //   window.location.href = '/login';
    toast.error('Session expired. Please login again')
      
    }

    return Promise.reject(error);
  }
);

export default api;