import React from 'react';
import ForgotPassword from '../components/ForgotPassword';

const ForgotPasswordPage = () => {
    return (
        <div className=' flex justify-center items-center h-screen '>
            <ForgotPassword/>
        </div>
    );
}

export default ForgotPasswordPage;
