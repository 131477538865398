import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { clearAuthData } from '../Redux/AuthSlice';

const PrivateRoute = ({type}) => {
    const token = useSelector((state)=>state.auth.token)
    const user_type = useSelector((state) => state.auth.type);

    const isCorrectUserType = user_type === type;
    const isAuthenticated = token && token !== "";
    const dispatch = useDispatch();

    

    useEffect(() => {
        if (!isAuthenticated || !isCorrectUserType ) {
          dispatch(clearAuthData());
        }
      }, [dispatch, isAuthenticated, isCorrectUserType]);

    return isAuthenticated && isCorrectUserType  ? (
        <Outlet />
      ) : (
        <Navigate to="/login" replace />
      );
}

export default PrivateRoute;
