import React, { useEffect, useState } from 'react';
import { RiShareBoxLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { fetchSubscribedPlans } from '../Redux/SubscribedPlansSlice';

const SubscriptionPage = () => {

const dispatch = useDispatch()

const { id, token } = useSelector((state) => state.auth)
const [UpdatePasswordModal,setUpdatePasswordModal] = useState(false)




useEffect(()=>{
    dispatch(fetchSubscribedPlans({userId:id,token}))
},[dispatch,id,token])
    return (
        <div className='h-screen overflow-auto scrollbar-thin scrollbar-webkit relative'>
            <div className='flex flex-col sm:flex-row gap-3 sm:gap-0 sm:justify-between sm:items-center '>
                <p className='text-sm lg:text-xl'>Subscriptions</p>
            </div>
            <Outlet />
        </div>
    );
}

export default SubscriptionPage;
