import React, { useState } from 'react';
import logo from '../assets/logo.png'
import ngh from '../assets/ngh-white.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoIosMenu } from "react-icons/io";
import { IoClose, IoMenu } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthData, logoutState } from '../Redux/AuthSlice';
import { TbLogout2 } from "react-icons/tb";

import axios from 'axios';
import { api_url } from '../config';
import api from '../utils/api';

const Nav = () => {
    const location = useLocation()
    const isLoginPage = location.pathname === '/login';
    const isProductsPage = location.pathname === '/products';
    const isDashboardPage = location.pathname === '/dashboard';
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)

    const token = useSelector((state) => state.auth.token)

    const handleLogout = async (e) => {
        e.preventDefault();

        try {
            dispatch(logoutState(true))

            await api.post(api_url + '/logout', {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });


            dispatch(clearAuthData());

            // Manipulate the browser's history to prevent going back to protected pages
            window.history.pushState(null, '', '/');
            window.onpopstate = () => {
                window.history.pushState(null, '', '/');
            };
            dispatch(logoutState(false))
            // Navigate to the login page
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Logout error:', error);
            // Handle error if needed
        }
    };


    return (
       
        <nav className={`h-16 sm:h-20 lg:h-[78px] flex justify-between items-center px-4 sm:px-8 lg:px-[109px] ${isProductsPage ? 'hidden' : 'fixed w-full top-0 left-0 z-50 bg-white shadow-md'}`}>
            <div>
                <Link to='/'>
                    <img src={logo} className='h-10 sm:h-12 lg:h-[60px] w-auto' alt="Logo" />
                </Link>
            </div>

            {/* Desktop Navigation */}
            <div className='hidden md:flex items-center space-x-4'>
                <Link to='/products' className=' cursor-pointer'>
                    <p className='px-4 py-2 font-semibold text-sm lg:text-[15px] hover:text-blue-600'>Products</p>
                </Link>
                {(isLoginPage || !isDashboardPage) && (
                    <Link to='/login'>
                        <button className='px-4 py-2 font-semibold text-sm lg:text-[15px] hover:text-blue-600'>Login</button>
                    </Link>
                )}
                {isDashboardPage && token && (
                    <div className='flex items-center gap-2 rounded-md shadow-md hover:opacity-80 px-2'>
                        <TbLogout2 size={22} />
                        <button onClick={handleLogout} className='py-2'>logout</button>
                    </div>
                )}
            </div>

            {/* Mobile Menu Button */}
            <div className='md:hidden'>
                <button onClick={toggleMenu}>
                    {isMenuOpen ? <IoClose size={24} /> : <IoMenu size={24} />}
                </button>
            </div>

            {/* Mobile Navigation */}
            <div className={`md:hidden absolute top-16 left-0 right-0 bg-white shadow-md overflow-hidden transition-all duration-450 ease-in-out ${isMenuOpen ? 'max-h-56' : 'max-h-0'}`}>
                <div className='flex flex-col items-center py-4'>
                    <Link to='/products' className='w-full text-center py-2 hover:bg-gray-100'>
                        <p className='font-semibold text-sm'>Products</p>
                    </Link>
                    {(isLoginPage || !isDashboardPage) && (
                        <Link to='/login' className='w-full text-center py-2 hover:bg-gray-100'>
                            <p className='font-semibold text-sm'>Login</p>
                        </Link>
                    )}
                    {isDashboardPage && token && (
                        <button onClick={handleLogout} className='w-full text-center py-2 hover:bg-gray-100 font-semibold text-sm'>
                            Logout
                        </button>
                    )}
                </div>
            </div>
        </nav>
            );
}

export default Nav;
