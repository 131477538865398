import React, { useEffect, useState } from 'react';
import GenerateTokenModal from './GenerateTokenModal';
import { IoAddCircleOutline } from 'react-icons/io5';
import api from '../../utils/api';
import { api_url } from '../../config';
import { useSelector } from 'react-redux';
import { FaTrash } from 'react-icons/fa';
import { format } from 'date-fns';
import DeleteTokenModal from './DeleteTokenModal';
import { MutatingDots } from 'react-loader-spinner';
import { RxShadowNone } from 'react-icons/rx';


const TokenSection = () => {
    const [open, setOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const { id, token } = useSelector((state) => state.auth)
    const [loading, setLoading] = useState(false)
    const [tokens, setTokens] = useState([])
    const [selectedToken, setSlectedToken] = useState('')

    const fetchTokens = async () => {
        setLoading(true)
        const params = {
            page: 1,
            limit: 50,

        };
        try {
            const response = await api.get(api_url + `/v1/admin/${id}/tokens`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }, params
            });
            const data = response.data.data;

            setTokens(data);

        } catch (error) {
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchTokens()
    }, [])

    const onDeleteToken = (tokenId) => {
        setDeleteModal(true)
        setSlectedToken(tokenId)
    }



    return (
        <div>
            {loading && (
                <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <MutatingDots
                        visible={true}
                        height="100"
                        width="100"
                        color="#6C48C5"
                        secondaryColor="#6C48C5"
                        radius="12.5"
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
            <div>
                <button
                    onClick={() => setOpen(true)}
                    className="px-4 py-2 text-xs my-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none flex justify-center items-center gap-2"
                >
                    <IoAddCircleOutline size={20} />
                    Generate Token
                </button>
            </div>

            {tokens?.length > 0 ? <div className="overflow-x-auto bg-white shadow-md rounded-lg">

                <table className="min-w-full leading-normal ">
                    <thead className='bg-gray-200'>
                        <tr className='bg-gradient-to-r from-purple-300 via-blue-300 to-yellow-300 bg-[length:100%_6px] bg-no-repeat bg-bottom '>
                            <th className="px-3 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                Name
                            </th>
                            <th className="px-3 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                Date Created
                            </th>
                            <th className="px-3 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {tokens.map((token) => (
                            <tr key={token.id}>
                                <td className="px-3 py-3 border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap">{token.name}</p>
                                </td>
                                <td className="px-3 py-3 border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap">
                                        {format(new Date(token.created_at), 'MMM dd, yyyy HH:mm')}
                                    </p>
                                </td>
                                <td className="px-3 py-3 border-b border-gray-200 bg-white text-sm">
                                    <button
                                        onClick={() => onDeleteToken(token.id)}
                                        className="text-red-600 hover:text-red-900 transition duration-150 ease-in-out flex items-center gap-1 px-2 py-1 rounded-md bg-red-100"
                                    >
                                        <FaTrash />
                                        <p className='text-xs'>Delete</p>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> :
                <div className='h-full w-full flex flex-col items-center justify-center py-8 gap-4'>
                    <RxShadowNone size={38} className='text-red-300 ' />
                    <p className='text-xs'>There no tokens, Kindly add to get started!</p>
                </div>
            }
            <DeleteTokenModal deleteModal={deleteModal} onClose={() => setDeleteModal(false)} fetchTokens={fetchTokens} selectedToken={selectedToken} />
            <GenerateTokenModal open={open} onClose={() => setOpen(false)} fetchTokens={fetchTokens}>
                <IoAddCircleOutline size={20} />
            </GenerateTokenModal>
        </div>
    );
}

export default TokenSection;