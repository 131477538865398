import React from 'react';

const TermsOfUse = () => {
    return (
        <div className=''>
              <h1 className="text-3xl font-bold mb-6">Terms of Use</h1>

<section className="mb-6">
  <h2 className="text-2xl font-semibold mb-4">1. Acceptance of the Terms of Use</h2>
  <p>
    Welcome to the website of NtuBed Global Talents, LLC, (the “Company”, “we” or “us”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use”), govern your access to and use of https://www.ntubedglobal.com , including any content, functionality and services offered on or through such website (the “Website”), whether as a guest or a registered user.
  </p>
  <p>
    Please read the Terms of Use carefully before you start to use the Website. By using the Website or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at http://www.ntubedglobal.com/privacy/ , incorporated herein by reference, and that you have the authority to be bound by these Terms of Use, on your and/or the company for which you are an employee or agent. If you do not want to agree to these Terms of Use or the Privacy Policy, you are prohibited from accessing or using the Website. This Website is offered and available to users who are 18 years of age or older. By using this Website, you represent and warrant that you are of legal age to form a binding contract with the Company.
  </p>
</section>

<section className="mb-6">
  <h2 className="text-2xl font-semibold mb-4">2. Changes to the Terms of Use</h2>
  <p>
    We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Website thereafter. However, any changes to the dispute resolution provisions set forth in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the Website.
  </p>
  <p>
    Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page each time you access this Website so you are aware of any changes, as they are binding on you. We may also provide notice to you of revised Terms of Use upon entry to the Website as prominently displayed or via other communications (e.g., electronic mail).
  </p>
</section>

<section className="mb-6">
  <h2 className="text-2xl font-semibold mb-4">3. Accessing the Website and Account Security</h2>
  <p>
    We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.
  </p>
  <p>
    You are responsible for:
  </p>
  <ul className="list-disc list-inside pl-5 mb-4">
    <li>Making all arrangements necessary for you to have access to the Website (e.g., use of your browser and computer).</li>
    <li>Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.</li>
  </ul>
  <p>
    To access our Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current and complete. You agree that all information you provide to register with this Website or otherwise, including but not limited to through the use of any interactive features on the Website, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.
  </p>
</section>
           
        </div>
    );
}

export default TermsOfUse;
