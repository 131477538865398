import React from 'react';
import LoginForm from '../components/LoginForm';

const LoginPage = () => {
    return (
        <div className='flex items-center justify-center h-[80vh] sm:h-screen w-full'>
            <LoginForm/>
        </div>
    );
}

export default LoginPage;
