import axios from 'axios';
import React, { useState } from 'react';
import { Hourglass, InfinitySpin } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'
import { api_url } from '../config';


const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState('')
    const [status, setStatus] = useState('')
    const handleSendEmail = async (e) => {
        e.preventDefault()
        const form = {
            email
        }
        try {
            setLoading(true);
            const response = await axios.post(api_url+'/forgot-password', form, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            console.log(response);
            // dispatch(setAuthData(response.data)) 
            if (response.status == 200) {
                setStatus('The link has been sent to your email')
                setTimeout(() => {
                    setStatus('');
                }, 6000);

            }
            // navigate('/dashboard')
            console.log(status, response.status);

        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
            console.log(error?.response?.data?.error);
        } finally {
            setLoading(false);
            setEmail('')
        }

    }
    return (
        <div className=' m-2 shadow px-6 py-4 flex flex-col justify-center items-center gap-10 sm:gap-4 md:w-[40vw] xl:w-[30vw] bg-white rounded-lg'>
          
            <form className='space-y-4 md:space-y-4' >
                <div>
                    <p className='text-xl font-medium text-neutral-600 text-center mb-4 sm:mb-2 font-lufga'>Forgot Password?</p>
                    <p className='text-sm text-neutral-600 text-center font-lufga'>Enter the email you registered with and we will send you a link to reset your password</p>
                </div>
                <div>
                    <label
                        htmlFor='email'
                        className='block font-lufga mb-2 text-sm font-medium text-gray-900 '
                    >
                        Email
                    </label>
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type='email'
                        name='email'
                        id='email'
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:outline-none  block w-full p-2.5 '
                        placeholder='admin@company.com'
                        required
                    />
                </div>
                {status ? <div>
                    <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{status}</p>
                </div> : error ? <div>
                    <p className="bg-red-300 rounded-md font-lufga text-center shadow-md px-2 py-1 text-sm ">
                        {typeof error === 'string' ? error : (
                            Object.keys(error).map((key) => (
                                Array.isArray(error[key]) ? error[key][0] : error[key]
                            ))
                        )}
                    </p>
                </div> : null}

               <div className='flex justify-end'>
               <button
                    onClick={handleSendEmail}

                    className=' font-lufga text-white bg-blue-500 hover:opacity-90  focus:outline-none rounded-md shadow-md text-xs sm:text-sm px-5 py-2 sm:px-10 sm:py-3 text-center '
                >

                    Send email
                </button>
               </div>
                {loading ? (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <Hourglass
                        visible={true}
                        height="40"
                        width="40"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#306cce', '#72a1ed']}
                        loading={true}
                    />
                </div>) : null}
                <div className='flex justify-between'>
                    <Link to='/login'>
                        <p className='hover:underline font-lufga cursor-pointer text-sm text-blue-800'>Go back</p>
                    </Link>

                </div>
            </form>
        </div>
    );
}

export default ForgotPassword;
