import React from 'react';
import SignupForm from '../components/SignupForm';

const SignupPage = () => {
    return (
        <div className='flex items-center justify-center py-4 '>
            <SignupForm/>
        </div>
    );
}

export default SignupPage;
