import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import { Outlet } from 'react-router-dom';
import { Hourglass } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import VerifyEmailModal from '../components/VerifyEmailModal';
import { LuArrowRight } from 'react-icons/lu';

const AppLayout = () => {
    const [open, setOpen] = useState(false);
    
    const { loading, email_verified_at,sub_code,sub_product } = useSelector((state) => state.auth);


    return (
        <div className=''>
            <div className='flex justify-center sm:p-20 w-full h-fit overflow-auto '>
                <div className='w-[20%] bg-blue-100 bg-opacity-65 backdrop-filter backdrop-blur-sm rounded-l-lg py-4 pr-2 '>
                    <Sidebar />
                    {/* <LuArrowRight className='absolute cursor-pointer -right-3 top-9 w-7 border-2 border-blue-950 rounded-full z-50'/> */}
                </div>
                <div className='w-[80%] bg-blue-100  backdrop-filter backdrop-blur-sm rounded-r-lg p-4 h-full overflow-auto'>
                    <Outlet />
                </div>
            </div>

            <div>
                {(!email_verified_at || email_verified_at === 'null') && <VerifyEmailModal />}

            </div>
        </div>
    );
}

export default AppLayout;
