import React, { useEffect, useState } from 'react';
import AddProductForm from '../components/AdminLib/AddProductForm';
import { useDispatch, useSelector } from 'react-redux';
import { CiLogout } from 'react-icons/ci';
import { api_url } from '../config';
import api from '../utils/api';
import { clearAuthData, logoutState } from '../Redux/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { MutatingDots } from 'react-loader-spinner';
import VerifyEmailModal from '../components/AdminLib/VerifyEmailModal';
import TokenSection from '../components/AdminLib/TokenSection';

const CreatePlans = () => {
    const { email, name, token, id, email_verified_at } = useSelector((state) => state.auth)
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState(null)
    const [strip_data, setStripe_data] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const[isActive,setActive] = useState('plans')

    const fetchStripeData = async () => {
        setLoading(loading)
        try {
            const response = await api.get(api_url + `/v1/stripe/admin/${id}/sub-data`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            const data = response.data.data;

            setStripe_data(data);

        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false)
        }
    };

    const fetchPlans = async () => {
        setLoading(loading)
        const params = {
            page: 1,
            limit: 50,

        };
        try {
            const response = await api.get(api_url + `/v1/admin/${id}/plans`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }, params
            });
            const data = response.data.data;

            setProducts(data);

        } catch (error) {
        } finally {
            setLoading(false)
        }
    };



    useEffect(() => {
        fetchStripeData()
        fetchPlans()
    }, [])


    const activeTab = (tab) =>{
        if(tab=='plans'){
            setActive('plans')
        }else if(tab=='tokens'){
            setActive('tokens')
        }
    }

    const handleLogout = async () => {
        try {
            // dispatch(logoutState(true));
            setLoading(true)

            await api.post(
                api_url + "/admin/logout",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            dispatch(clearAuthData());

            // Manipulate the browser's history to prevent going back to protected pages
            window.history.pushState(null, "", "/admin-login");
            window.onpopstate = () => {
                window.history.pushState(null, "", "/admin-login");
            };

            // dispatch(logoutState(false));
            navigate("/admin-login", { replace: true });
        } catch (error) {
            console.error("Logout error:", error);
        } finally {
            setLoading(false)
        }
    };

    return (
        <div className='w-full'>
            {loading && ( // Display loader while loading
                <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <MutatingDots
                        visible={true}
                        height="100"
                        width="100"
                        color="#4fa94d"
                        secondaryColor="#4fa94d"
                        radius="12.5"
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
            <div className='mx-10 my-10 '>
                <div className='flex justify-between '>
                    <div>
                        <p className='font-semibold'>{name}</p>
                        <p>{email}</p>
                    </div>
                    <div>
                        <CiLogout onClick={handleLogout} size={28} className='text-red-500 hover:scale-110 duration-300 cursor-pointer' />
                        <p className='text-[10px]'>Logout</p>
                    </div>

                </div>
                <div className='flex mt-4 items-center gap-4'>
                    <button
                    onClick={()=>activeTab('plans')}
                     className={`px-2 py-1 border-b hover:bg-purple-50 ${isActive == 'plans' ? 'border-purple-500':''}`} >Plans</button>
                    <button 
                    onClick={()=>activeTab('tokens')}
                    className={`px-2 py-1 border-b hover:bg-purple-50 ${isActive == 'tokens' ? 'border-purple-500':''}`}  >Tokens</button>
                </div>
{           isActive == 'plans' &&     <AddProductForm products={products} fetchPlans={fetchPlans} stripeData={strip_data} />
}           
{           isActive == 'tokens' &&     <TokenSection />
}           
 </div>

            {/* <div>
                {(!email_verified_at || email_verified_at === 'null') && <VerifyEmailModal />}

            </div> */}

        </div>
    );
}

export default CreatePlans;
