import React, { useState } from 'react';
import { AiTwotoneDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import DeletePlanModal from './DeletePlanModal';
import { MdClose } from 'react-icons/md';
import EditPlanForm from './EditPlanForm';
import { RxBorderNone, RxShadowNone } from 'react-icons/rx';

const ProductsTable = ({ products, fetchPlans,stripeData }) => {
    const [showDeletePlanModal, setShowDeletePlanModal] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [planToDelete, setPlanToDelete] = useState(null);
    const [planToEdit, setPlanToEdit] = useState(null);

    const handleDeleteUser = (planId) => {
        // if (event) {
        //     event.preventDefault(); 
        //     event.stopPropagation();
        // }
        const planToDelete = products.find(product => product.id === planId);
        if (planToDelete) {
            setShowDeletePlanModal(true);
            setPlanToDelete(planToDelete);
        }
    };

    const handleEditPlan = (planId) => {
        // if (event) {
        //     event.preventDefault();
        //     event.stopPropagation();
        // }
        const planToEdit = products.find(product => product.id === planId);
        if (planToEdit) {
            setShowEditForm(true);
            setPlanToEdit(planToEdit);
        }
    };

    console.log(products);
    
    return (
        <div className="">
           { products?.length > 0 ? (<table className="bg-white border border-gray-300 h-full overflow-auto shadow-md w-full ">
                <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-xs leading-normal sticky -top-1">
                        <th className="py-3 px-2 text-left">Package</th>
                        <th className="py-3 px-2 text-left">Price</th>
                        <th className="py-3 px-6 text-left">Features</th>
                        <th className="py-3 px-3 text-left">Description</th>
                        <th className="py-3 px-2 text-left">S. Prod ID</th>
                        <th className="py-3 px-6 text-left">S. Price ID</th>
                        <th className="py-3 px-2 text-left">Billing</th>
                        <th className="py-3 px-2 text-left">Slug</th>
                        <th className="py-3 px-2 text-left">Status</th>
                        <th className="py-3  text-left">Apps</th>
                        <th className="py-3 px-6 text-left">Action</th>
                    </tr>
                </thead>
                {products?.map((product) => (
                    <tbody key={product.id} className="text-gray-600 text-xs">
                        <tr className="border-b border-gray-200 hover:bg-gray-100">
                            <td className="py-3 px-2 text-left">{product.name}</td>
                            <td className="py-3 px-2 text-left">{product.price}</td>
                            <td className="py-3 text-left">
                                {product?.features?.map((feature, index) => (
                                    <div key={index}>{feature}</div>
                                ))}
                            </td>
                            <td className="py-3 px-3 text-left">{product.description}</td>
                            <td className="py-3 px-2 text-left">{product.stripe_product}</td>
                            <td className="py-3 px-6 text-left">{product.stripe_price}</td>
                            <td className="py-3 px-2 text-left">{product.billable}</td>
                            <td className="py-3 px-2 text-left">{product.slug}</td>
                            <td className="py-3 px-2 text-left">{product.status}</td>
                            <td className="py-3 text-left">{product.app}</td>
                            <td className="py-3 px-6 hover:cursor-pointer flex items-center gap-2 justify-center h-full">
                                <FiEdit size={15}
                                onClick={() => handleEditPlan(product.id)}
                                className='hover:text-blue-800 hover:scale-110 duration-300' />

                                <AiTwotoneDelete 
                                onClick={(event) => { handleDeleteUser(product.id) }}
                                 size={15}
                                  className='hover:text-red-600 hover:scale-110 duration-300' />
                            </td>

                        </tr>

                    </tbody>
                ))}
            </table>):
          <div className='h-full w-full flex flex-col items-center justify-center py-8 gap-4'>
             <RxShadowNone size={38} className='text-red-300 '/>
             <p>There no plans, Kindly add to get started!</p>
          </div>
        }
            {showDeletePlanModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-md min-h-max shadow-lg p-6">
                        {/* <MdClose size={24} onClick={() => setShowDeletePlanModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' /> */}
                        <DeletePlanModal toggle={() => setShowDeletePlanModal(false)} fetchPlans={fetchPlans} plan={planToDelete} />
                    </div>
                </div>
            )}

            
                <div className={`fixed top-0 right-0 w-full md:w-1/3 h-full bg-white shadow-lg transform transition-transform duration-300 ${showEditForm ? 'translate-x-0' : 'translate-x-full'}`}>
                    <EditPlanForm
                        plan={planToEdit}
                        onClose={() => setShowEditForm(false)}
                        fetchPlans={fetchPlans}
                        stripeData ={stripeData}
                    />
                </div>
            


        </div>
    );
};

export default ProductsTable;



