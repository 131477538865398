import React from 'react';
import ConfirmEmailVerification from '../components/ConfirmEmailVerification';

const ConfirmEmailVerificationPage = () => {
    return (
        <div>
            <ConfirmEmailVerification/>
        </div>
    );
}

export default ConfirmEmailVerificationPage;
