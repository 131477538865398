import React, { useEffect, useRef, useState } from 'react';
import hero from '../assets/hero.png'
import transformImg from '../assets/tranform-section.png'
import investImg from '../assets/invest-section.png'
import optimiseImg from '../assets/optimise-section.png'
import securityImg from '../assets/securityImg.png'
import headsetImg from '../assets/headset-bg.png'
import logo from '../assets/logo.png'
import footer from '../assets/footer.png'
import { MdArrowUpward, MdLocalPhone, MdOutlineEmail } from 'react-icons/md';
import { SlLocationPin } from 'react-icons/sl';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';
import TermsOfUse from './termsOfUse';
import { IoMdClose } from 'react-icons/io';
import PrivacyPolicy from './privacyPolicy';


const Home = () => {
    const [showScroll, setShowScroll] = useState(false);
    const [showTerms, setShowTerms] = useState(false)
    const [showPrivacy, setShowPrivacy] = useState(false)
    const modalRef = useRef()

    useEffect(() => {
        Aos.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
    }, [])


    useEffect(() => {


        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowScroll(true);
            } else {
                setShowScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <div className='mt-16 lg:mt-[78px]'>
            <div className='relative'>
                <img src={hero} className='w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-auto object-cover' alt="Hero" />
                <div className='absolute inset-0 sm:bg-white bg-black bg-opacity-40 sm:bg-opacity-5 flex items-center' data-aos="slide-right">
                    <div className='text-white px-4 sm:px-8 md:px-12 lg:px-16 xl:px-[60px] flex flex-col gap-4 md:gap-6 lg:gap-[48px] max-w-[85%] md:max-w-xl lg:max-w-2xl 2xl:max-w-[60%]'>
                        <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-medium leading-6' >
                            Empower your business, clients and patients care for best outcomes.
                        </h1>
                        <p className='text-sm sm:text-base md:text-lg' >
                            Empowering your services with advanced information technology solutions
                        </p>
                        <button className='shadow-md w-[120px] h-[50px] sm:w-[171px] sm:h-[58px] sm:py-[18px] sm:px-[36px] py-[10px] px-[20px] bg-[#0097B2] text-white rounded-[6px] hover:text-[#0097B2] hover:bg-white'><span className='w-[59px] h-[22px] font-semibold text-sm sm:text-[18px] leading-[22px]'>Contact Us</span></button>
                    </div>
                </div>
            </div>

            <div className='max-w-[1440px] mx-auto'>
                <div className='flex flex-col xl:flex-row xl:items-center xl:h-[494px] p-4 xl:p-0  '>
                    <div className='xl:h-[410px] xl:w-[744px] flex flex-col gap-[10px] xl:px-[60px]' data-aos="fade-right">
                        <div>
                            <p className='text-3xl xl:text-[48px] font-bold xl:h-[116px] xl:leading-[58px] text-[#004AAD]'>
                                Transform your healthcare delivery
                            </p>
                        </div>
                        <div className='flex flex-col gap-4 xl:h-[276px]'>
                            <p className='font-semibold text-xl xl:text-[20px]'>Are you ready for the next evolution in healthcare?</p>
                            <p className='text-sm xl:leading-5'>In today's market environment, healthcare organizations are confronted with greater challenges such as shifting payment models, increased competition, regulatory changes, clinical advances, new technologies, workforce trends, and increased competition.</p>
                            <p className='text-sm xl:leading-5'>In order to transition to a value-based healthcare delivery system, your organization needs a rapid transformation in your healthcare delivery and management. A significant player in that transformation is healthcare information technology.</p>
                            <p className='text-sm xl:leading-5'>Choosing us as your partner will help you Optimize your delivery approach, redesign your workflows, and plan for the future. When it comes to your transformation journey, we are your #1 choice because we can help you achieve your goals every step of the way.</p>
                        </div>
                    </div>
                    <div className='xl:w-[636px] xl:h-[494px] mt-4 xl:mt-0' data-aos="fade-down">
                        <img src={transformImg} alt="Transform" className='w-full h-auto md:w-2/3 lg:w-1/2 xl:w-auto  mx-auto' />
                    </div>
                </div>

                <div className='flex flex-col-reverse xl:flex-row xl:items-center xl:h-[454px] p-4 xl:p-0' data-aos="fade-down">
                    <div className='xl:h-[400px] mt-4 xl:mt-0'>
                        <img src={investImg} alt="Transform" className='w-full h-auto md:w-2/3 lg:w-1/2 xl:w-auto  mx-auto' />
                    </div>
                    <div className='xl:h-[410px] xl:w-[85%] flex flex-col gap-[10px] xl:px-[60px] mt-4 xl:mt-0' data-aos="fade-right">
                        <div>
                            <p className='text-3xl xl:text-[43px] font-bold xl:h-[58px] xl:leading-[58px] text-[#004AAD]'>
                                Invest in Healthcare Technology
                            </p>
                        </div>
                        <div className='flex flex-col gap-4 xl:h-[276px]'>
                            <p className='font-semibold text-xl xl:text-[20px]'>Which technologies will drive your vision?</p>
                            <p className='text-sm xl:leading-5'>Compared to other industries, healthcare has been relatively slow to adopt technological innovations. But the explosion in emerging technologies, coupled with a growing demand for technology to support the patient experience, are driving organizations to rethink technology's strategic impact.</p>
                            <p className='text-sm xl:leading-5'>Mobile apps, advances in home and self-monitoring devices, and telemedicine services are already impacting organizational models, operations, and workforce. You therefore need to decide whether to partner, adopt, or compete with these new entities and models.</p>
                            <p className='text-sm xl:leading-5'>As these trends increase and healthcare systems evolve, NtuBed Global Healthcare is here to help you plan, select, implement, and optimize the technologies that best align with your vision.</p>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col xl:flex-row xl:items-center xl:h-[454px] p-4 xl:p-0'>
                    <div className='xl:h-[410px] xl:w-[85%] flex flex-col gap-[10px] xl:px-[60px]' data-aos="fade-up">
                        <div>
                            <p className='text-3xl xl:text-[43px] font-bold xl:h-[58px] xl:leading-[58px] text-[#004AAD]'>
                                Optimise your business system
                            </p>
                        </div>
                        <div className='flex flex-col gap-4 xl:h-[276px]'>
                            <p className='font-semibold text-xl xl:text-[20px]'>Where will you drive your business performance improvements?</p>
                            <p className='text-sm xl:leading-5'>You need to identify which workflows to redesign or eliminate in order to improve your performance clinically. For many organizations, this can be an overwhelming task for an already overworked internal team.</p>
                            <p className='text-sm xl:leading-5'>Our business optimization services can provide an objective set of eyes on your business performance. Our experienced consultants will work with you to gather and analyze your business data, conduct high level process reviews, and interview your staff to carefully identify optimal improvements that will save you time and money.</p>
                            <p className='text-sm xl:leading-5'>We understand that improvements to process workflows and technologies can dramatically reduce costs and optimize your service delivery. When you choose NtuBed Global as your partner, we can help you realize the improvements your organization needs</p>
                        </div>
                    </div>
                    <div className='xl:h-[400px] mt-4 xl:mt-0' data-aos="zoom-in">
                        <img src={optimiseImg} alt="Transform" className='w-full h-auto md:w-2/3 lg:w-1/2 xl:w-auto  mx-auto' />
                    </div>
                </div>

                <div className='relative '>
                    <img src={headsetImg} className='w-full h-[500px] sm:h-[400px] md:h-[500px] lg:h-[600px] xl:h-[700px] 2xl:h-auto object-cover object-left ' alt="Hero" />
                    <div className='absolute inset-0 flex items-center justify-end lg:justify-left bg-gradient-to-r from-purple-900/70 to-transparent '>
                        <div className='text-white px-4 sm:px-8 md:px-12 lg:px-16 xl:px-[60px] flex flex-col gap-2 sm:max-w-[65%]  mr-4 lg:mr-0' data-aos="zoom-in">
                            <h1 className='text-2xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-medium leading-tight'>
                                ARE YOU READY FOR GO-LIVE?
                            </h1>
                            <p className='font-semibold text-lg sm:text-xl xl:text-[20px]'>Go-Live Support & Training</p>
                            <p className='text-xs  sm:text-sm xl:text-base xl:leading-5'>You need to identify which workflows to redesign or eliminate in order to improve your performance clinically. For many organizations, this can be an overwhelming task for an already overworked internal team</p>
                            <p className='text-xs  sm:text-sm xl:text-base xl:leading-5'>Our business optimization services can provide an objective set of eyes on your business performance. Our experienced consultants will work with you to gather and analyze your business data, conduct high level process reviews, and interview your staff to carefully identify optimal improvements that will save you time and money.</p>
                            <p className='text-xs  sm:text-sm xl:text-base xl:leading-5'>We understand that improvements to process workflows and technologies can dramatically reduce costs and optimize your service delivery. When you choose NtuBed Global as your partner, we can help you realize the improvements your organization needs</p>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col-reverse sm:flex-row items-center p-4 xl:mx-72 sm:mx-32 gap-4 my-20'>
                    <div className='mb-4 xl:mb-0'>
                        <img src={securityImg} alt="Security" className='w-full h-auto lg:w-3/4 lg:mx-auto xl:w-auto' data-aos="slide-down" />
                    </div>
                    <div className='xl:ml-8'>
                        <p className='text-3xl xl:text-[43px] font-bold xl:h-[58px] xl:leading-[58px] text-[#004AAD] mb-4'>
                            FEATURES
                        </p>
                        <p className='font-semibold text-xl xl:text-[20px]'>Security</p>
                        <p className='text-sm xl:leading-5'>
                            We have a professionally managed data center that undergoes rigorous annual audits. We employ strict control procedures making sure your data is safe, reliable and secured.
                        </p>
                    </div>
                </div>

                <footer
                    className="bg-gray-100 py-8 px-4 md:px-12 lg:px-24 bg-contain bg-center"
                    style={{ backgroundImage: `url(${footer})` }}
                >
                    <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center">
                        <div className="flex flex-col items-center lg:items-start mb-8 lg:mb-0">
                            <img src={logo} alt="Logo" className='h-10 sm:h-12 lg:h-[60px] w-auto' />
                            <p className="text-gray-600 text-xs mb-2">
                                Where healthcare and technology intersect
                            </p>
                            <div className="text-gray-600 text-sm flex flex-col gap-3">
                                <div><span className='flex items-center gap-3'><MdOutlineEmail size={20} /><p> info@ntubedglobal.com</p></span></div>
                                <div><span className='flex items-center gap-3'><MdLocalPhone size={20} /><p> (+233) 030 396 4289</p></span></div>
                                <div><span className='flex items-center gap-3'><SlLocationPin size={20} /><p> Basket Junction, Adj. Omni Bank,<br /> Spintex Road, Accra, Ghana</p></span></div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between w-full lg:w-auto lg:space-x-16">
                            <div className="flex flex-col items-center lg:items-start mb-8 lg:mb-0">
                                <h5 className="text-lg font-bold mb-4">Discover More</h5>
                                <div className="text-gray-600 flex flex-col gap-2">
                                    <button onClick={() => setShowTerms(true)}><a className="hover:text-blue-500">Terms of Use</a></button>
                                    <button onClick={() => setShowPrivacy(true)}><a className="hover:text-blue-500">Privacy Policy</a></button>
                                    <Link to='/products' className="hover:text-blue-500">IT Products</Link>

                                </div>
                            </div>
                            <div className="flex flex-col items-center lg:items-start">
                                <h5 className="text-lg font-bold mb-4">Account</h5>
                                <div className="text-gray-600 flex flex-col gap-3">
                                    <Link to='/login' className="hover:text-blue-500">Sign In</Link>
                                    <Link to='/signup' className="hover:text-blue-500">Sign Up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center border-t border-gray-300 mt-8 pt-4">
                        <p className="text-gray-500 text-xs">Terms & Conditions</p>
                        <p className="text-gray-500 text-xs">Ntubed GLobal LTD; All right reserved</p>
                    </div>
                </footer>
            </div>
            {showScroll && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-4 right-4 p-3 bg-blue-600 text-white rounded-full shadow-lg transition-opacity hover:opacity-80"
                >
                    <MdArrowUpward size={24} />
                </button>
            )}

            {showTerms && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6 max-h-[70vh] w-[70%] overflow-auto">
                        <IoMdClose size={24} onClick={() => setShowTerms(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                        <TermsOfUse />
                    </div>
                </div>
            )}
            {showPrivacy && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6 ">
                        <IoMdClose size={24} onClick={() => setShowPrivacy(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                        <PrivacyPolicy />
                    </div>
                </div>
            )}


        </div>
    );
}

export default Home;