import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { HiOutlineViewGrid } from 'react-icons/hi';
import { LuGitBranchPlus } from 'react-icons/lu';
import { MdAccountBox, MdPayment, MdPayments } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const location = useLocation()
    const getActiveMenuItem = (path) => {
        if (path.includes('/subscription')) return 'Subscriptions';
        if (path.includes('/account')) return 'Account';
    
        return '';
    };

    const activeMenuItem = getActiveMenuItem(location.pathname);
    return (
        <div className='overflow-y-auto'>
        
        <div className='flex flex-col gap-2 '>

            <Link to='account'>
                <div
                    className={`flex items-center rounded-r-xl gap-2 p-2 hover:opacity-75 ${activeMenuItem === 'Account' ? 'bg-slate-100 border-l-2 border-blue-500' : ''}`}
                >
                    <FaUserCircle  size={20} className='text-gray-800 '/>
                    <button className={`hidden text-xs lg:text-base md:flex  ${activeMenuItem === 'Account' ? 'text-blue-500 ' : 'text-gray-800'} `}>Account</button>

                </div>
            </Link>

            <Link to='subscriptions'>
                <div
                    className={`flex items-center rounded-r-xl gap-2 p-2 hover:opacity-75 ${activeMenuItem === 'Subscriptions' ? 'bg-slate-100 border-l-2 border-blue-500' : ''}`}
                >
                    <MdPayment size={20} className='text-gray-800' />

                    <button className={`hidden text-xs lg:text-base md:flex  ${activeMenuItem === 'Subscriptions' ? 'text-blue-500' : 'text-gray-800'}`}>Subscriptions</button>

                </div>
            </Link>


        </div>
    </div>
    );
}

export default Sidebar;
