import React from 'react';
import { RiShareBoxLine } from 'react-icons/ri';
import { Hourglass } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import phartrix_logo from '../assets/pharmtrix-logo.png'
import retail_logo from '../assets/rlogo.png'
const SubscribedPlans = () => {
    const navigate = useNavigate()
    // const subs = useSelector((state) => state.subscribedPlans.data)
    const { data: subs, isLoading, error } = useSelector(state => state.subscribedPlans);



    const showSubscripton = (data) => {
        navigate(`${data?.id}`,{
            state: {
               ...data
            }
        })
    }


    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    if (isLoading) {
       return (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
             <Hourglass
                 visible={true}
                 height="40"
                 width="40"
                 ariaLabel="hourglass-loading"
                 wrapperStyle={{}}
                 wrapperClass=""
                 colors={['#306cce', '#72a1ed']}
             />
         </div>)
     }

    if (error) {
        return <div className='flex justify-center items-center w-full h-full '>Oops an error occurred</div>;
    }

    return (
        <div className=''>
            <div className='flex flex-col sm:flex-row gap-3 sm:gap-0 sm:justify-end mb-5'>
                {subs.length > 0 && (
                    <Link to='/plans'>
                        <button className='flex items-center justify-center gap-1 text-xs text-blue-600 border border-gray-400 py-1 px-2 rounded-md shadow-md '>
                            <span>Buy Products</span>
                            <RiShareBoxLine />
                        </button>
                    </Link>
                )}
            </div>

            <div>
                {subs.length === 0 ?
                    (<div className='h-52 flex flex-col gap-4 justify-center items-center'>
                        <p className='text-center'>You have no subscriptions! Click on the button below to get started.</p>
                        <Link to='/plans'>
                            <button className='flex items-center justify-center gap-1 text-xs text-blue-600 border border-gray-400 py-1 px-2 rounded-md shadow-md animate-bounce hover:animate-none'>
                                <span>Buy Products</span>
                                <RiShareBoxLine />
                            </button>
                        </Link>
                    </div>)
                    :

                  (  subs?.map((item, index) => {
                        return (
                            <div key={item.id} className=''>
                                <span className='flex flex-col gap-4 sm:gap-0 sm:flex-row justify-between sm:items-center py-4 px-2 bg-white rounded-md shadow-md my-4 border hover:border-blue-600 '>
                                    <div className='flex items-center gap-3'>
                                        <div>
                                            {item?.plan?.app == 'pharmtrix-wholesale' && <img src={phartrix_logo } className='h-8 w-10 rounded-sm shadow-md'/>}
                                            {item?.plan?.app == 'pharmtrix-retail' && <img src={retail_logo } className='h-8 w-10 rounded-sm shadow-md'/>}
                                            
                                        </div>
                                        <div>
                                            <p className='text-sm sm:text-base font-semibold'>{item?.plan?.name}</p>
                                            {/* <p className='text-xs text-gray-500'>{item?.app}</p> */}
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => { showSubscripton(item) }}
                                            className='border border-gray-300 text-xs py-1 px-2 rounded-[4px] shadow-sm hover:shadow-lg'>
                                            Manage
                                        </button>
                                    </div>
                                </span>
                            </div>
                        )
                    }))
                }
            </div>
        </div>
    );
}

export default SubscribedPlans;
