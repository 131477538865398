import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Redux/AuthSlice';
import subscribedPlansReducer from './Redux/SubscribedPlansSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    subscribedPlans: subscribedPlansReducer

  },
});

export default store;
