import React, { useEffect, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import api from '../utils/api';
import { api_url } from '../config';
import UpdatePassword from './UpdatePassword';
import { IoMdClose } from 'react-icons/io';
import { toast } from 'react-toastify';
import UpdateCardDetails from './UpdateCardDetails';

const AccountDetails = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState([]);
    const [UpdatePasswordModal, setUpdatePasswordModal] = useState(false)
    const [UpdateCardModal, setUpdateCardModal] = useState(false)



    const { id, token } = useSelector((state) => state.auth)
    const fetchUserProfile = async () => {

        {
            setLoading(true)
            try {
                const response = await api.get(api_url + `/v1/user/${id}/profile`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                });
                const data = response.data.data;

                setUserData(data);

            } catch (error) {

            } finally {
                setLoading(false)
            }
        };
    };

    useEffect(() => {
        fetchUserProfile()
    }, [UpdateCardModal])
    const [editingData, setEditingData] = useState({ ...userData });


    const handleEdit = () => {
        setEditingData({ ...userData });
        setIsEditing(true);
    };

    const handleCancel = () => {
        setEditingData({ ...userData });
        setIsEditing(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditingData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // const handleUpdate = (e) => {
    //     e.preventDefault();
    //     setUserData({ ...editingData });
    //     console.log('Updated user data:', editingData);
    //     setIsEditing(false);
    // };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await api.put(api_url + `/v1/user/${id}/profile`, editingData, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            setUserData(response.data.data);
            setIsEditing(false);
            toast.success('Update successful')
        } catch (error) {
            console.error('Failed to update user data:', error);
            setError('Failed to update user data. Please try again.');
            toast.error('Failed to update user data. Please try again.')
        } finally {
            setLoading(false);
        }
    };


    const renderViewMode = () => (
        <div className='flex flex-col gap-3 text-xs lg:text-sm'>
            <div className='flex flex-col justify-start gap-4 lg:gap-10'>
                <p className='text-sm lg:text-xl'>Account Profile</p>
                <FaUserCircle className='text-4xl lg:text-[120px]' />
            </div>
            <div>
                <p className='border-b border-blue-700 py-2 font-semibold'>Personal Information</p>
                <div className='py-4 lg:py-8 grid sm:grid-cols-2 gap-4'>
                    <span>
                        <p>Admin Name</p>
                        <p>{userData.name}</p>
                    </span>
                    <span>
                        <p>Email</p>
                        <p>{userData.email}</p>
                    </span>
                </div>
            </div>
            <div>
                <p className='border-b border-blue-700 py-2 font-semibold'>Company Information</p>
                <div className='py-4 lg:py-8 grid sm:grid-cols-2 gap-4'>
                    <span>
                        <p>Company Name</p>
                        <p>{userData.company_name}</p>
                    </span>
                    <span>
                        <p>Company Mobile Number</p>
                        <p>{userData.company_phone}</p>
                    </span>
                    <span>
                        <p>Company Type</p>
                        <p>{userData.company_type}</p>
                    </span>
                    <span>
                        <p>Company Website</p>
                        <p>{userData.company_domain}</p>
                    </span>
                </div>
            </div>
            <div>
                <p className='border-b border-blue-700 py-2 font-semibold'>Address Information</p>
                <div className='py-4 lg:py-8 grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-y-8'>
                    <span>
                        <p>Company Address</p>
                        <p>{userData.address}</p>
                    </span>
                    <span>
                        <p>Country</p>
                        <p>{userData.country}</p>
                    </span>
                    <span>
                        <p>City</p>
                        <p>{userData.city}</p>
                    </span>
                    <span>
                        <p>Town</p>
                        <p>{userData.town}</p>
                    </span>
                    <span>
                        <p>Zip Code</p>
                        <p>{userData.zip_code}</p>
                    </span>
                </div>
            </div>
            <div>
                <p className='border-b border-blue-700 py-2 font-semibold'>Card Details</p>
                <div className='py-4 lg:py-8 grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-y-8'>
                    <span>
                        <p>Card number</p>
                        <p className=''>{`**** **** **** ${userData?.pm_last_four}`}</p>
                    </span>
                    <span>
                        <p>Type</p>
                        <p className='font-extrabold '>{userData?.pm_type}</p>
                    </span>
                </div>
            </div>
            <div>
                <p className='border-b border-blue-700 py-2 font-semibold'>Account Security</p>
                <div className='py-4 lg:py-8 grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-y-8'>
                    <span>
                        <p>Password</p>
                        <p className='font-extrabold text-xl'>...........</p>
                    </span>
                </div>
            </div>
            <div className='flex justify-end m-4'>
                <button onClick={handleEdit} className='py-2 px-4 border border-blue-800 text-blue-800 rounded-lg hover:opacity-75'>Update Profile</button>
            </div>
        </div>
    );

    const renderEditMode = () => (
        <form onSubmit={handleUpdate} className='flex flex-col gap-3 text-xs lg:text-sm'>
            <div className='flex flex-col justify-start gap-4 lg:gap-10'>
                <p className='text-sm lg:text-xl'>Edit Account Profile</p>
                <FaUserCircle className='text-4xl lg:text-[120px]' />
            </div>

            <div>
                <p className='border-b border-blue-700 py-2 font-semibold'>Personal Information</p>
                <div className='py-4 lg:py-8 grid sm:grid-cols-2 gap-4'>
                    <span>
                        <p>Admin Name</p>
                        <input
                            type="text"
                            name="name"
                            value={editingData.name}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded bg-transparent border-slate-400 focus:outline-none"
                        />
                    </span>
                    <span>
                        <p>Email</p>
                        <input
                            type="email"
                            name="email"
                            value={editingData.email}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded bg-transparent border-slate-400 focus:outline-none"
                        />
                    </span>
                </div>
            </div>

            <div>
                <p className='border-b border-blue-700 py-2 font-semibold'>Company Information</p>
                <div className='py-4 lg:py-8 grid sm:grid-cols-2 gap-4'>
                    <span>
                        <p>Company Name</p>
                        <input
                            type="text"
                            name="company_name"
                            value={editingData.company_name}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded bg-transparent border-slate-400 focus:outline-none"
                        />
                    </span>
                    <span>
                        <p>Company Mobile Number</p>
                        <input
                            type="text"
                            name="company_phone"
                            value={editingData.company_phone}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded bg-transparent border-slate-400 focus:outline-none"
                        />
                    </span>
                    <span>
                        <p>Company Type</p>
                        <select
                            name="company_type"
                            value={editingData.company_type || ''}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded bg-transparent border-slate-400 focus:outline-none"
                            required
                        >
                            <option value="" disabled>Select Company Type</option>
                            <option value="hospital">Hospital</option>
                            <option value="pharmacy">Pharmacy</option>
                            <option value="insurance_provider">Insurance Provider</option>
                            <option value="eye_clinic">Eye clinic</option>
                            <option value="medical_clinic">Medical clinic</option>
                            <option value="other">Other</option>
                        </select>
                    </span>
                    <span>
                        <p>Company Website</p>
                        <input
                            type="text"
                            name="compnay_website"
                            value={editingData.company_domain}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded bg-transparent border-slate-400 focus:outline-none"
                        />
                    </span>
                </div>
            </div>

            <div>
                <p className='border-b border-blue-700 py-2 font-semibold'>Address Information</p>
                <div className='py-4 lg:py-8 grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-y-8'>
                    <span>
                        <p>Address</p>
                        <input
                            type="text"
                            name="address"
                            value={editingData.address}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded bg-transparent border-slate-400 focus:outline-none"
                        />
                    </span>
                    <span>
                        <p>Country</p>
                        <input
                            type="text"
                            name="country"
                            value={editingData.country}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded bg-transparent border-slate-400 focus:outline-none"
                        />
                    </span>
                    <span>
                        <p>City</p>
                        <input
                            type="text"
                            name="city"
                            value={editingData.city}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded bg-transparent border-slate-400 focus:outline-none"
                        />
                    </span>
                    <span>
                        <p>Town</p>
                        <input
                            type="text"
                            name="town"
                            value={editingData.town}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded bg-transparent border-slate-400 focus:outline-none"
                        />
                    </span>
                    <span>
                        <p>Zip Code</p>
                        <input
                            type="text"
                            name="zip_code"
                            value={editingData.zip_code}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded bg-transparent border-slate-400 focus:outline-none"
                        />
                    </span>
                </div>
                <div>
                    <p className='border-b border-blue-700 py-2 font-semibold'>Card Details</p>
                    <div className='py-4 lg:py-8 grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-y-8'>
                        <span>
                            <p>Card number</p>
                            <p className=''>{`**** **** **** ${userData?.pm_last_four}`}</p>
                        </span>
                        <span>
                            <p>Type</p>
                            <p className='font-extrabold '>{userData?.pm_type}</p>
                        </span>
                        <span>
                            <button
                                type="button"
                                onClick={() => { setUpdateCardModal(true) }}
                                className='py-2 px-4 bg-gray-300 text-gray-800 rounded-sm hover:opacity-75 font-semibold mt-2'>
                                Update card details
                            </button>
                        </span>
                    </div>
                </div>

                <div>
                    <p className='border-b border-blue-700 py-2 font-semibold'>Account Security</p>
                    <div className='py-4 lg:py-8 gap-4 sm:gap-y-8'>
                        <span>
                            <p>Password</p>
                            <button
                                type="button"
                                onClick={() => { setUpdatePasswordModal(true) }}
                                className='py-2 px-4 bg-gray-300 text-gray-800 rounded-sm hover:opacity-75 font-semibold mt-2'>
                                Change Password
                            </button>
                        </span>
                    </div>
                </div>
            </div>

            <div className='flex justify-end gap-4 m-4'>
                <button type="button" onClick={handleCancel} className='py-2 px-4 border border-gray-300 text-gray-600 rounded-lg hover:opacity-75'>Cancel</button>
                <button type="submit"
                    disabled={loading}
                    className='py-2 px-4 bg-blue-600 text-white rounded-lg hover:opacity-75'>{loading ? 'updating...' : 'Update'}</button>
            </div>
        </form>
    );

    return (
        <div className=''>
            {isEditing ? renderEditMode() : renderViewMode()}

            {
                UpdatePasswordModal && (
                    <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-70 backdrop-blur-sm">
                        <UpdatePassword toggle={() => { setUpdatePasswordModal(false) }} />

                    </div>
                )
            }
            {
                UpdateCardModal && (
                    <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-70 backdrop-blur-sm">
                        <UpdateCardDetails toggle={() => { setUpdateCardModal(false) }} />

                    </div>
                )
            }
        </div>
    );
}

export default AccountDetails;