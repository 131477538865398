import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { api_url } from '../config';
import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Bars, MutatingDots } from 'react-loader-spinner';
import ErrorModal from '../components/ErrorModal';
import api from '../utils/api';


function capitalizeFirstLetter(word) {
    if (typeof word !== 'string' || word.length === 0) {
        return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
}

const ProductCard = ({ title, description, status, isActive, onClick }) => (

    <div
        className={`bg-white rounded-lg shadow-md p-3 cursor-pointer ${isActive ? 'border-[1px] border-blue-500 bg-gradient-to-b from-green-200 to-white' : 'border-[1px]'}`}
        onClick={onClick}
    >
        <h3 className="text-base font-semibold text-gray-800">{capitalizeFirstLetter(title)}</h3>
        <p className="text-xs text-gray-600 mt-2">{capitalizeFirstLetter(description)}</p>
        <p className="text-xs text-blue-600 mt-2">{capitalizeFirstLetter(status)}</p>
    </div>
);


const PlanCard = ({ id, name, price, billable, description, features, stripe_price, onBuy, loader }) => (
    <div
        className="bg-gradient-to-b from-blue-200 to-white rounded-2xl shadow-lg py-4 px-6 cursor-pointer border-[1px] border-blue-100 hover:border-blue-300 transition-all duration-300 my-4 font-lufga flex flex-col "
    >
        <div>
            <h3 className="text-xl font-bold text-gray-900 mb-1">{capitalizeFirstLetter(name)}</h3>
            <h3 className="text-xl font-semibold text-gray-700">{'$' + price} / {billable}</h3>
            <p className="text-sm text-gray-600 mt-2">{capitalizeFirstLetter(description)}</p>
            <div className="mt-4">
                {features?.map((feature, index) => (
                    <div key={index} className="flex items-center text-blue-600 text-sm mt-2">
                        <FaCheckCircle className="mr-2" />
                        {feature}
                    </div>
                ))}
            </div>
        </div>
        <div className='mt-auto self-end'>
            <button
                onClick={onBuy}
                className="bg-blue-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">
                {loader ? <Bars
                    height="15"
                    width="40"
                    color="#FFFFFF"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                /> : `Buy now`}
            </button>
        </div>
    </div>
);


const PlanComponent = ({ title, planData }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const { id, token } = useSelector((state) => state.auth)
    const [card_loader, setCard_loader] = useState(false)
    const [errorModal, setErrorModal] = useState(false)

    const navigate = useNavigate()


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const getClientSecret = async (plan) => {

        try {
            setCard_loader(true)
            const data = await api.get(`${api_url}/v1/user/${id}/check-payment-method`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(data);

            const client_secret = data?.data?.client_secret
            const message = data?.data?.message
            const user_name = data?.data?.name
            const user_email = data?.data?.email
            const pm_type = data?.data?.pm_type
            const pm_last_four = data?.data?.pm_last_four


            navigate('/subscribe',
                {
                    state: {
                        client_secret,
                        message,
                        user_name,
                        user_email,
                        pm_type,
                        pm_last_four,
                        plan
                    }
                }
            )
        } catch (error) {
            console.log(error);
            setErrorModal(true)
        } finally {
            setCard_loader(false)

        }
    }
    return (
        <div>
            <div className="mt-8">
                {isMobile ? (
                    <div className='mx-3'>
                        <Slider {...settings}>
                            {planData?.map((plan, index) => {
                                {
                                    if(plan?.status == "active" ) {
                                       return (<div key={index} >
                                            <PlanCard
                                                {...plan}
                                                onBuy={() => { getClientSecret(plan) }}
                                                loader={card_loader}
                                            />
                                        </div>)
                                    }else{
                                        return(
                                            <div>
                                                <p>There no plans available yet!!</p>
                                            </div>
                                        )
                                    }
                                }
                            })}
                        </Slider>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-10" >
                        {planData?.map((plan, index) => {
                                {
                                    if(plan?.status == "active" ) {
                                       return (<div key={index} >
                                            <PlanCard
                                                {...plan}
                                                onBuy={() => { getClientSecret(plan) }}
                                                loader={card_loader}
                                            />
                                        </div>)
                                    }else{
                                        return(
                                            <div>
                                                <p>There no plans available yet!!</p>
                                            </div>
                                        )
                                    }
                                }
                            })}
                    </div>
                )}

                {errorModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-90">
                        <div className="bg-white rounded-md min-h-max shadow-lg p-3">
                            {/* <MdClose size={24} onClick={() => setShowDeletePlanModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' /> */}
                            <ErrorModal toggle={() => setErrorModal(false)} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const AuthProductPage = () => {
    const [activeProduct, setActiveProduct] = useState('pharmtrix-wholesale');
    const [planData, setPlanData] = useState(null);
    const [loading, setLoading] = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const { id, token } = useSelector((state) => state.auth)

    useEffect(() => {
        Aos.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        }, []);

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const products = [
        {
            title: "pharmtrix-wholesale",
            description: "Pharmacy Inventory Management. Medication tracking, inventory management, and dispensing for pharmacies and healthcare providers"
        },
        {
            title: "pharmtrix-retail",
            description: "Pharmacy Inventory Management. Medication tracking, inventory management, and dispensing for pharmacies and healthcare providers"
        },
        {
            title: "centrix",
            description: "Next-Gen Health Management. Centralized solutions for your hospital practice. Seamless for your entire medical ecosystem",
            status: "Coming Soon"
        },
        {
            title: "billtrix",
            description: "Medical Accounting Solutions. Streamline your accounting processes with our comprehensive medical billing software",
            status: "Coming Soon"
        },
        {
            title: "labtrix",
            description: "Managing all laboratory and imaging operations in one place. Efficient handling of all your medical results",
            status: "Coming Soon"
        }
    ];

    const fetchPlanData = async (productTitle) => {
        // Simulate API call
        // setPlanData(null); // Reset data while loading
        // const response = await fetch(`/api/plans/${productTitle}`);
        // const data = await response.json();
        // setPlanData(data);
        {
            setLoading(true)
            setPlanData(null);

            const params = {
                page: 1,
                limit: 50,
                [`filter[app]`]: productTitle,

            };
            try {
                const response = await api.get(api_url + `/v1/user/${id}/plans`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }, params
                });
                const data = response.data.data;

                setPlanData(data);

            } catch (error) {
            } finally {
                setLoading(false)
            }
        };
    };


    useEffect(() => {
        fetchPlanData(activeProduct);
    }, [activeProduct])

    const handleProductClick = (productTitle) => {
        setActiveProduct(productTitle);

    };


    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleProductClick(products[current].title),
    };




    return (
        <div>
            <div className="text-white">
                <header className="text-center my-32 sm:my-52" data-aos="zoom-in">
                    <h1 className="text-[24px] sm:text-[64px] font-semibold mb-4 leading-[30px] sm:leading-[77px]">
                        Elevate your Clinical & business operations with our digital solutions
                    </h1>
                    <p className="text-xs sm:text-xl">
                        Explore our suite of healthcare products engineered to optimize your operations and drive your business forward
                    </p>
                </header>

                {isMobile ? (
                    <div className='mx-3'>
                        <Slider {...settings}>
                            {products.map((product, index) => (
                                <div key={index} >
                                    <ProductCard
                                        {...product}
                                        isActive={activeProduct === product.title}
                                        onClick={() => handleProductClick(product.title)}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 mb-10">
                        {products.map((product, index) => (
                            <ProductCard
                                key={index}
                                {...product}
                                isActive={activeProduct === product.title}
                                onClick={() => handleProductClick(product.title)}
                            />
                        ))}
                    </div>
                )}
            </div>

            <div>
                <h2 className="text-2xl w-full text-center font-bold mt-4 sm:mb-4 font-lufga">{capitalizeFirstLetter(activeProduct)} Plans</h2>

                <div className='h-[50vh] sm:h-[60vh] sm:overflow-auto scrollbar-thin scrollbar-webkit px-3'>
                    {loading ?
                        <div className='h-full w-full flex justify-center items-center'
                        >
                            <MutatingDots
                                visible={true}
                                height="100"
                                width="100"
                                color="#4fa94d"
                                secondaryColor="#4fa94d"
                                radius="12.5"
                                ariaLabel="mutating-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                        :
                        <PlanComponent title={activeProduct} planData={planData} />}
                </div>
            </div>


        </div>
    );
};

export default AuthProductPage;