import React from 'react';
import { Outlet } from 'react-router-dom';
import NavHeader from '../components/NavHeader';
import backImage from '../assets/landingPage.png'
import { Hourglass } from 'react-loader-spinner';
import { useSelector } from 'react-redux';


const ProductPageLayout = () => {
  const loader = useSelector((state) => state.auth.loading)

  return (

    <div>
      <div className="min-h-screen relative">
        <img src={backImage} className="absolute inset-0 w-full h-full object-cover" alt="Background" />
        <div className="relative z-10">
          <NavHeader />
          <main className="container mx-auto px-4 py-16 md:py-8">
            <Outlet />
          </main>
        </div>
      </div>
      {loader && (
        <div className="absolute inset-0 flex justify-center items-center bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm z-50">
          <div className='flex flex-col items-center gap-4'>
            <Hourglass
              visible={true}
              height="40"
              width="40"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors='#ffff'
            />
            <p className='text-center'>Logging Out....</p>
          </div>
        </div>
      )}
    </div>



  );
};

export default ProductPageLayout;