import React, { useEffect, useState } from 'react';
import { CiBarcode, CiCircleInfo } from 'react-icons/ci';
import { GrStatusGoodSmall } from 'react-icons/gr';
import { IoIosArrowRoundBack, IoIosTimer } from 'react-icons/io';
import { IoCopyOutline, IoPricetagsOutline } from 'react-icons/io5';
import { PiUsersThin } from 'react-icons/pi';
import { RiShareBoxLine } from 'react-icons/ri';
import { SlShareAlt } from 'react-icons/sl';
import { TbBuildingCommunity, TbClockCancel } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';
import { api_url } from '../config';
import api from '../utils/api';
import { useSelector } from 'react-redux';
import { FaCheckCircle } from 'react-icons/fa';
import ErrorModal from '../components/ErrorModal';
import { toast } from 'react-toastify';
import DeactivatePlanModal from '../components/DeactivatePlanModal';

const SubscriptionViewPage = () => {
    const [isPlanOpen, setIsPlanOpen] = useState(true);
    const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false)
    const [planData, setPlanData] = useState([])
    const { id, token, name } = useSelector((state) => state.auth)
    const [errorModal, setErrorModal] = useState(false)
    const [deactivateModal, setDeactivateModal] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)
    const [error, setError] = useState(false)

    const handleCopy = () => {
        navigator.clipboard.writeText(subscription.sub_code).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    function capitalizeFirstLetter(word) {
        if (typeof word !== 'string' || word.length === 0) {
            return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
    const location = useLocation();


    const { plan, subscription } = location?.state

    const handleOpenCurrentPlan = () => {
        setIsPlanOpen(!isPlanOpen)
        setIsUpgradeOpen(false)
    }
    const handleOpenUpgradePlan = () => {
        setIsPlanOpen(false)
        setIsUpgradeOpen(!isUpgradeOpen)
    }

    const navigate = useNavigate()

    const fetchPlanData = async (productTitle) => {

        {
            setLoading(true)
            setPlanData(null);

            const params = {
                page: 1,
                limit: 50,
                [`filter[app]`]: productTitle,

            };
            try {
                const response = await api.get(api_url + `/v1/user/${id}/plans`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }, params
                });
                const data = response.data.data;

                setPlanData(data);

            } catch (error) {
            } finally {
                setLoading(false)
            }
        };
    };

    useEffect(() => {
        fetchPlanData(plan?.app);
    }, [plan])


    const getClientSecret = async (plan) => {
        setBtnLoader(true)
        try {
            const data = await api.get(`${api_url}/v1/user/${id}/check-payment-method`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(data);

            const client_secret = data?.data?.client_secret
            const message = data?.data?.message
            const user_name = data?.data?.name
            const user_email = data?.data?.email
            const pm_type = data?.data?.pm_type
            const pm_last_four = data?.data?.pm_last_four


            navigate('/subscribe',
                {
                    state: {
                        client_secret,
                        message,
                        user_name,
                        user_email,
                        pm_type,
                        pm_last_four,
                        plan
                    }
                }
            )
        } catch (error) {
            console.log(error);
            setErrorModal(true)
        } finally {
            setBtnLoader(false)
        }
    }

    const handleGoto = () => {
        const url = 'https://www.test1.ntubedglobal.com';
        window.open(url, '_blank');
    };

    function convertDate(dateString) {
        if(!dateString){
            return ' '
        }
        const date = new Date(dateString);
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    }

    return (
        <div className=''>
            <div className='w-fit hover:underline mt-4'>
                <button
                    onClick={() => { navigate('/subscriptions') }}
                    className='flex items-center rounded-md cursor-pointer hover:text-blue-600 '>
                    <IoIosArrowRoundBack />
                    <p className='text-xs'>back</p>
                </button>
            </div>
            <div className='mt-4 '>
                <p className='text-sm'>Hello {name},</p>
                <p className='text-sm'>Thank you for being a valued subscriber to {plan.app} </p>
            </div>

            <div className="w-full mx-auto mt-4 relative">
                <div className="bg-white rounded-lg shadow-md overflow-hidden">
                    <button
                        onClick={handleOpenCurrentPlan}
                        className="w-full px-4 py-3 flex justify-between items-center focus:outline-none"
                    >
                        <span className="font-medium">Current plan</span>
                        <svg
                            className={`w-5 h-5 transition-transform duration-200 ${isPlanOpen ? 'transform rotate-180' : ''
                                }`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                    <div
                        className={`transition-all duration-300 ease-in-out ${isPlanOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                            } overflow-hidden`}
                    >
                        <div className="px-4 py-3 border-t border-gray-200 flex flex-col gap-3 ">
                            <span className='flex flex-col sm:flex-row justify-between sm:items-center gap-2'>
                                <p className="text-sm font-semibold font-lufga text-gray-600">{plan.name}</p>
                                <button
                                    onClick={handleGoto}
                                    className='flex items-center w-fit gap-0.5 text-blue-500 bg-blue-200 px-2 py-1 rounded-md shadow-md border hover:border-blue-600'>
                                    <RiShareBoxLine />
                                    <p className="text-xs font-semibold font-lufga ">Go to {plan?.app}</p>
                                </button>
                            </span>
                            <span className='flex flex-col sm:flex-row sm:items-center gap-2'>
                                <span className='flex items-center gap-2'>
                                    <CiBarcode size={24} />
                                    <p className="text-sm  text-gray-600">Subscription code: <span className='font-semibold bg-blue-50 rounded-lg px-2 py-1'>{subscription.sub_code} </span></p>
                                </span>
                                <span className='flex items-center gap-1 '>
                                    <CiCircleInfo
                                        className='cursor-pointer hidden lg:block'
                                        onMouseEnter={() => setShowTooltip(true)}
                                        onMouseLeave={() => setShowTooltip(false)}
                                    />
                                    {showTooltip && (
                                        <div className="absolute top-5 sm:top-12 left-[40%] transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 mb-2 z-10">
                                            When setting up your {plan?.app} account, please make sure to copy your subscription code and provide it where necessary. This code is essential for accessing your subscription benefits and ensuring a seamless experience.
                                        </div>
                                    )}
                                    <IoCopyOutline
                                        className='cursor-pointer hover:text-blue-500'
                                        onClick={handleCopy}
                                    />
                                    {copied && (
                                        <span className="ml-2 text-green-500 text-xs">Copied!</span>
                                    )}
                                </span>
                            </span>
                            <span className='flex items-center gap-2'>
                                <IoPricetagsOutline size={22} />
                                <p className="text-sm text-gray-600">Price: ${plan.price}/{plan.billable}</p>
                            </span>
                            <span className='flex items-center gap-2'>
                                <TbBuildingCommunity size={24} />
                                <p className="text-sm text-gray-600">Branch coverage: Up to <span className='font-bold'>{plan?.branches_limit} </span>branches</p>
                            </span>
                            <span className='flex items-center gap-2'>
                                <PiUsersThin size={24} />
                                <p className="text-sm text-gray-600">User coverage: Up to <span className='font-bold'>{plan?.users_limit} </span>users</p>
                            </span >
                            <span className='flex items-center gap-2'>
                            <IoIosTimer size={24}/>
                            <p className="text-sm text-gray-600">Next billing  date: {convertDate(subscription?.expires_on)?.split(' ')[0]}</p>
                        </span>

                            <span className='flex flex-col sm:flex-row justify-between sm:items-center gap-2 sm:gap-0'>
                                <span className='flex items-center gap-2'>
                                    <GrStatusGoodSmall size={20} className={`${subscription.stripe_status == 'active' ? 'text-green-600 animate-pulse' : 'text-red-600'} `} />
                                    <p className="text-sm text-gray-600">Status: {subscription.stripe_status}</p>
                                </span>
                                <button
                                    onClick={() => { setDeactivateModal(true) }}
                                    className='flex w-fit items-center gap-0.5 text-red-500 bg-red-200 px-2 py-1 rounded-md shadow-md border hover:border-red-600'>
                                    <TbClockCancel size={20} />
                                    <p className="text-xs font-semibold font-lufga ">Deactivate</p>
                                </button>
                            </span>

                        </div>
                    </div>
                </div>
            </div>


            <div className="w-full mx-auto mt-4">
                <div className="bg-white rounded-lg shadow-md overflow-hidden">
                    <button
                        onClick={handleOpenUpgradePlan}
                        className="w-full px-4 py-3 flex justify-between items-center focus:outline-none"
                    >
                        <span className="font-medium">Upgrade plan</span>
                        <svg
                            className={`w-5 h-5 transition-transform duration-200 ${isUpgradeOpen ? 'transform rotate-180' : ''
                                }`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                    <div
                        className={`transition-all duration-300 ease-in-out ${isUpgradeOpen ? 'max-h-fit opacity-100 overflow-auto scrollbar-thin scrollbar-webkit' : 'max-h-0 opacity-0'
                            } `}
                    >
                        <div className="px-4 py-3 border-t border-gray-200 grid lg:grid-cols-2 gap-4  ">
                            {planData?.map((item) => {
                                {
                                    if (plan.id !== item.id && item?.status == 'active') {
                                        return (
                                            <div key={item.id} className=''>
                                                <div
                                                    className="  bg-gradient-to-b from-blue-200 to-white rounded-2xl shadow-lg py-4 px-6 cursor-pointer border-[1px] border-blue-100 hover:border-blue-300 transition-all duration-300 my-4 md:font-lufga flex flex-col "
                                                >
                                                    <div>
                                                        <h3 className="text-base font-bold text-gray-900 mb-1">{capitalizeFirstLetter(item.name)}</h3>
                                                        <h3 className="text-base font-semibold text-gray-700">{'$' + item.price} / {item.billable}</h3>
                                                        <p className="text-sm text-gray-600 mt-2">{capitalizeFirstLetter(item.description)}</p>
                                                        <div className="mt-2">
                                                            {item.features?.map((feature, index) => (
                                                                <div key={index} className="flex items-center text-blue-600 text-xs lg:text-sm mt-1">
                                                                    <FaCheckCircle className="mr-2" />
                                                                    {feature}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className='mt-auto self-end'>
                                                        <button
                                                            onClick={plan.price > item.price ? () => { toast.error('Plans are not downgradable!. Please contact our customer support lines. We are here to assist you!.') } : () => { getClientSecret(item) }}
                                                            disabled={btnLoader}
                                                            className="bg-blue-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">
                                                            Upgrade
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {errorModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-90">
                    <div className="bg-white rounded-md min-h-max shadow-lg p-3">
                        {/* <MdClose size={24} onClick={() => setShowDeletePlanModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' /> */}
                        <ErrorModal toggle={() => setErrorModal(false)} />
                    </div>
                </div>
            )}

            {deactivateModal && (
                <div className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-200 bg-opacity-70 backdrop-blur-sm">
                    <DeactivatePlanModal toggle={() => setDeactivateModal(false)} />
                </div>
            )}


        </div>
    );
}

export default SubscriptionViewPage;
