import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <p>Privacy</p>
        </div>
    );
}

export default PrivacyPolicy;
