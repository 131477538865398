import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import NavHeader from "./components/NavHeader";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import AccountPage from "./pages/AccountPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ConfirmEmailVerificationPage from "./pages/ConfirmEmailVerificationPage";
import PrivateRoute from "./utils/PrivateRoute";
import ProductPage from "./pages/ProductPage";
import ProductPageLayout from "./utils/ProductPageLayout";
import AppLayout from "./utils/AppLayout";
import SubscriptionPage from "./pages/SubscriptionPage";
import CreatePlans from "./pages/CreatePlans";
import LoginForm from "./components/AdminLib/LoginForm";
import ForgotPassword from "./components/AdminLib/ForgotPassword";
import VerifyEmailModal from "./components/AdminLib/VerifyEmailModal";
import ResetPassword from "./components/AdminLib/ResetPassword";
import ConfirmEmailVerification from "./components/AdminLib/ConfirmEmailVerification";
import SignUpForm from "./components/AdminLib/SignUpForm";
import AuthProductPage from "./pages/AuthProductPage";
import SubscriptionsPage from "./components/SubscribeForm";
import SubscriptionViewPage from "./pages/SubscriptionViewPage";
import SubscribedPlans from "./components/SubscribedPlans";

function App() {
  return (
    <Routes>
      <Route element={<ProductPageLayout />}>
        <Route path="/products" element={<ProductPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/sub-reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/sub-verify-email/:id/:hash" element={<ConfirmEmailVerificationPage />} />
        <Route element={<PrivateRoute type="user" />}>
          <Route element={<AppLayout />}>
            <Route path="/account" element={<AccountPage />} />
            <Route path="/subscriptions" element={<SubscriptionPage />} >
              <Route index element={<SubscribedPlans />} />
              <Route path=":id" element={<SubscriptionViewPage />} />
            </Route>
          </Route>
          <Route path="/plans" element={<AuthProductPage />} />
          <Route path="/subscribe" element={<SubscriptionsPage />} />
        </Route>
      </Route>
      <Route element={<LayoutWithNav />}>
        <Route path="/" element={<HomePage />} />
      </Route>
      <Route path="/admin-login" element={<LoginForm />} />
      <Route path="/admin-signup" element={<SignUpForm />} />
      <Route path="/admin-forgot-password" element={<ForgotPassword />} />
      <Route path="/admin-verify-email" element={<VerifyEmailModal />} />
      <Route path="/admin-reset-password/:token" element={<ResetPassword />} />
      <Route path="/admin-verify-email/:id/:hash" element={<ConfirmEmailVerification />} />
      <Route element={<PrivateRoute type="admin" />}>
        <Route path="/add-product" element={<CreatePlans />} />
      </Route>
    </Routes>
  );
}

function LayoutWithNav() {
  return (
    <div>
      <Nav />
      <Outlet />
    </div>
  );
}

function LayoutWithNavHeader() {
  return (
    <div>
      <NavHeader />
      <Outlet />
    </div>
  );
}

export default App;