import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api_url } from '../../config';
import { clearAuthData, logoutState } from '../../Redux/AuthSlice';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';

const VerifyEmailModal = () => {
    const { product_name, email, token } = useSelector((state) => state.auth)
    const [error, setError] = useState('')
    const [status, setStatus] = useState('')
    const [isloading, setIsLoading] = useState(false)
    const handleSendVerificationEmail = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await api.post(api_url + '/admin/email/verification-notification', email, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
            console.log(response.data);
            setStatus(response.data.status)
            setTimeout(() => {
                setStatus('');
            }, 6000);

        } catch (error) {
            console.log(error);
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
        } finally {
            setIsLoading(false);
        }
    }

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)
    const loader = useSelector((state) => state.auth.loading)


    const handleLogout = async (e) => {
        e.preventDefault();

        try {
            dispatch(logoutState(true))

            await api.post(api_url+'/admin/logout', {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });


            dispatch(clearAuthData());

            // Manipulate the browser's history to prevent going back to protected pages
            window.history.pushState(null, '', '/');
            window.onpopstate = () => {
                window.history.pushState(null, '', '/');
            };
            dispatch(logoutState(false))
            // Navigate to the login page
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Logout error:', error);
            // Handle error if needed
        }
    };

    return (
        <div>
            <div className="fixed inset-0 font-lufga overflow-y-auto m-4 sm:m-0 z-50">
                <div className="flex items-center justify-center min-h-screen">
                    <div className="fixed inset-0 bg-neutral-800 bg-opacity-90 transition-opacity" aria-hidden="true"></div>
                    <div className="relative bg-white rounded-sm p-8 max-w-md w-full mx-auto">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-lg font-bold text-gray-900">Email Verification Required</h2>
                        </div>
                        <p className="text-gray-700 mb-4 text-sm">You need to verify your email address before proceeding. Kindly check your email.</p>
                        <div className="flex justify-end mb-4">
                            <button onClick={handleLogout} className="bg-neutral-500 hover:bg-neutral-600 text-sm text-white shadow-md font-semibold py-2 px-4 rounded focus:outline-none mr-2">{loader ? 'Logging out...':'Logout'}</button>
                            <button onClick={handleSendVerificationEmail} className="bg-blue-500 hover:bg-blue-600 text-sm text-white shadow-md font-semibold py-2 px-4 rounded focus:outline-none mr-2">{isloading ? 'Sending...' : 'Resend Email'}</button>
                        </div>
                        {typeof status === 'string' && status && (
                        <div>
                            <p className="bg-green-300 rounded-md shadow-md px-2 py-1 text-xs text-center">{status}</p>
                        </div>
                    )}
                     {error && (
                        <div>
                            <p className="bg-red-300 rounded-md text-center shadow-md px-2 py-1 text-sm">
                                {typeof error === 'string'
                                    ? error
                                    : Object.keys(error).map((key) =>
                                        Array.isArray(error[key]) ? error[key][0] : error[key]
                                    )}
                            </p>
                        </div>
                    )}
                    </div>
                   

                   
                </div>
            </div>
        </div>
    );
}

export default VerifyEmailModal;
