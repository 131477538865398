import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { api_url } from '../config';
import ErrorModal from './ErrorModal';
import { FaCheckCircle } from 'react-icons/fa';
import successgif from '../assets/p_success.gif';
import master from '../assets/master.png'
import amercanexp from '../assets/americanexp.png';
import jcb from '../assets/jcblogo.png'
import api from '../utils/api';
const stripePromise = loadStripe("pk_test_51PFxC3P8g8umbZa8uwJOciaIf19nB9mvBFQJX3x3lVEmh1PB3gvQAruPld2uPp1eOb4S96Z3svv0OYSvObWERa6l00p0dQadfl");

const SubscribeForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    // const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const location = useLocation();
    const navigate = useNavigate()
    const { id, token, email } = useSelector((state) => state.auth);

    const { client_secret, plan, message, pm_type, pm_last_four, user_email, user_name } = location?.state;
   



    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        setError(null);

        try {


            const { error, setupIntent } = await stripe.confirmCardSetup(client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        email: email,
                    },
                },
            });

            if (error) {
                setError(error.message);
            } else if (setupIntent.status === 'succeeded') {
                await axios.post(api_url + `/v1/user/${id}/plans/${plan?.id}/checkout-subscribe`, { ...setupIntent }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })

                setSuccessful(true)
                setTimeout(()=>{
                    navigate('/subscriptions')
                },3000)
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };



    const handleConfirm = async (e) => {
        e.preventDefault()
        setLoading(true);

        try {
            const response = await api.post(api_url + `/v1/user/${id}/plans/${plan?.id}/subscribe`, {},{
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            setSuccessful(true)
            setTimeout(()=>{
                navigate('/subscriptions')
            },3000)
            
        } catch (error) {
            console.log(error);
            if(error?.response?.status == 403 ){
                setError("You're already on this plan")
            }else{
                setError(error?.response?.data?.message);
            }
        } finally {
            setLoading(false)
        }
    }



    const { name, price, billable, description, features, stripe_price, } = plan
    function capitalizeFirstLetter(word) {
        if (typeof word !== 'string' || word.length === 0) {
            return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const cardLogos = {
        
        discover: "https://www.discover.com/company/images/newsroom/media-downloads/discover.png",
        // americanExpress: "https://upload.wikimedia.org/wikipedia/commons/6/6c/American_Express_logo.svg",
        jcb: "https://upload.wikimedia.org/wikipedia/commons/e/ed/JCB_logo.svg",
        dinersClub: "https://www.discover.com/company/images/newsroom/media-downloads/diners-club.png",
        visa:"https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png"
      };
      


    return (
        <div className="h-screen w-full flex flex-col sm:flex-row justify-center items-center sm:gap-10 ">
            <div
                className=" max-w-lg bg-gradient-to-b from-blue-200 to-white rounded-2xl shadow-lg py-4 px-6 cursor-pointer border-[1px] border-blue-100 hover:border-blue-300 transition-all duration-300 my-4 font-lufga"
            >
                <h3 className="text-xl font-bold text-gray-900 mb-1">{capitalizeFirstLetter(name)}</h3>
                <h3 className="text-xl font-semibold text-gray-700">{'$' + price} / {billable}</h3>
                <p className="text-sm text-gray-600 mt-2">{capitalizeFirstLetter(description)}</p>

                <div className="mt-4 w-full">
                    {features?.map((feature, index) => (
                        <div key={index} className="flex items-center text-blue-600 text-sm mt-2">
                            <FaCheckCircle className="mr-2" />
                            {feature}
                        </div>
                    ))}
                </div>



            </div>
            {successful ?
                <div className="w-full max-w-lg p-6 m-6 bg-gradient-to-t from-green-100 to-white rounded-lg shadow-md grid place-items-center">
                    <div className='flex flex-col justify-center items-center '>
                        <img src={successgif} alt='payment_successul' className='h-[100px] w-[100px]' />
                        <p className='text-xs font-lufga'>Payment Succesful</p>
                        <p className='text-xs font-lufga'>redirecting....</p>
                    </div>
                </div>
                :
                (
                    <form className="w-full max-w-lg p-6 m-6 bg-gradient-to-t from-green-100 to-white rounded-lg shadow-md">
                        <h2 className="sm:text-2xl font-semibold mb-4">Subscribe to Plan</h2>
                        <input
                            type="email"
                            value={email}
                            // onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="w-full p-2 mb-4 border border-gray-300 rounded"
                            required
                            disabled
                        />
                        {client_secret ?
                            <div className="p-2 mb-4 border border-gray-300 rounded">
                                <CardElement />
                            </div> :
                            // <div>
                            //     <p>{pm_type}</p>
                            //     <p>{pm_last_four}</p>
                            // </div>
                            <div className="flex items-center justify-between p-4 bg-blue-50 rounded-lg shadow mb-4">
                                <div className="flex items-center">
                                    {/* Visa logo */}
                                    <div className="mr-4">
                                        {pm_type == 'visa' && <img
                                            src={cardLogos.visa}
                                            alt={pm_type}
                                            className="w-10 h-4"
                                        />}
                                        {pm_type == 'discover' && <img
                                            src={cardLogos.discover}
                                            alt={pm_type}
                                            className="w-10 h-10"
                                        />}
                                        {pm_type == 'mastercard' && <img
                                            src={master}
                                            alt={pm_type}
                                            className="w-10 h-8"
                                        />}
                                        {pm_type == 'amex' && <img
                                            src={amercanexp}
                                            alt={pm_type}
                                            className="w-10 h-8"
                                        />}
                                        {pm_type == '' && <img
                                            src={amercanexp}
                                            alt={pm_type}
                                            className="w-10 h-8"
                                        />}
                                    </div>

                                    <div>
                                        <h3 className="text-lg font-semibold text-gray-900">{user_name}</h3>
                                        <p className="text-sm text-gray-600">**** {pm_last_four}</p>
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <span className="text-sm font-medium text-blue-600 bg-blue-100 px-2 py-1 rounded-lg mr-4">
                                        Primary
                                    </span>
                                    <span className="w-6 h-6 bg-blue-600 text-white flex items-center justify-center rounded-full">
                                        ✓
                                    </span>
                                </div>
                            </div>
                        }
                        
                        <button
                            onClick={client_secret ? handleSubmit : handleConfirm}
                            disabled={!stripe || loading}
                            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm w-full mb-2"
                        >
                            {loading ? "Processing..." : `${client_secret ? 'Subscribe' : 'Confirm'}`}
                        </button>
                        <Link to='/plans'>
                            <p className='hover:underline font-lufga cursor-pointer text-sm text-blue-800'>Go back</p>
                        </Link>
                        {/* {error && <div className="mt-4 text-red-500">{error}</div>} */}
                        <div className='flex justify-between items-center'>
                            <div>
                                {status ? <div>
                                    <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{status}</p>
                                </div> : error ? <div>
                                    <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-xs ">
                                        {typeof error === 'string' ? error : (
                                            Object.keys(error).map((key) => (
                                                Array.isArray(error[key]) ? error[key][0] : error[key]
                                            ))
                                        )}
                                    </p>
                                </div> : null}
                            </div>
                        </div>
                    </form>
                )}


        </div>
    );
};

const SubscriptionsPage = () => (
    <Elements stripe={stripePromise}>
        <SubscribeForm />
    </Elements>
);

export default SubscriptionsPage;
