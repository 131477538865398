import React from 'react';

const ErrorModal = ({toggle}) => {
    return (
        <div className="card sm:w-96 bg-neutral text-neutral-content">
        <div className="card-body items-center text-center">
           
            <h2 className="font-bold">Error!</h2>

            <div>
                <p className='text-xs sm:text-sm'>Oops, Something went wrong. Please try again later!</p>
            </div>
            <div className="flex  justify-end gap-3 my-1">
                <button onClick={() => { toggle() }} className="bg-gray-500 font-lufga text-white py-1 text-xs px-4 rounded-md hover:bg-gray-600 shadow-md transition duration-300">Close</button>
            </div>
            
        </div>
    </div>
    );
}

export default ErrorModal;
