import React from 'react';
import { IoMdCall } from 'react-icons/io';
import { MdClose } from 'react-icons/md';

const DeactivatePlanModal = ({ toggle }) => {
    return (
        <div className="bg-white rounded-lg shadow-xl max-w-md ">
            <div className="flex justify-between items-center px-4 py-2  mb-4 border-b border-blue-500">
                <h2 className="text-sm text-blue-500">Deactivate Subscription</h2>
                <MdClose
                    size={24}
                    onClick={toggle}
                    className="cursor-pointer hover:text-gray-700"
                />
            </div>
            <p className="text-gray-600 mb-6 px-4">
                To cancel your subscription, please call our customer service lines. We're here to assist you!
                <span className='flex items-center gap-1 justify-center mt-2'>
                    <IoMdCall size={22} />
                    <p>: +233 030 396 4289</p>
                </span>
            </p>


        </div>
    );
};

export default DeactivatePlanModal;