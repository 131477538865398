import React, { useEffect, useState } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { FaRegEyeSlash } from 'react-icons/fa';
import { Hourglass, InfinitySpin } from 'react-loader-spinner';
import { Link, useSearchParams, useParams } from 'react-router-dom';
import axios from 'axios';
import { api_url } from '../config';
import logo from '../assets/logo.png'


const ResetPassword = () => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState('')
    const [selectedOption, setSelectedOption] = useState(null);
    const [status, setStatus] = useState('')
    const [passwordState, setPasswordState] = useState(false);
    const [confirm_passwordState, setConfirm_passwordState] = useState(false)
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [email, setEmail] = useState('')
    const [searchParams] = useSearchParams()
    const { token } = useParams()

    useEffect(() => {
        setEmail(searchParams.get("email"))
    }, [])
    console.log(email, token);


    const handlePasswordToggle = (e) => {
        e.preventDefault();
        setPasswordState(!passwordState);
    };
    const handleConfirmPasswordToggle = (e) => {
        e.preventDefault();
        setConfirm_passwordState(!confirm_passwordState)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true);
            const response = await axios.post(api_url + '/reset-password', {
                email,
                token,
                password,
                password_confirmation
            });
            if (response.status == 200) {
                setStatus('Password reset successful')
                setTimeout(() => {
                    setStatus('');
                }, 10000);

            }
        } catch (error) {
            console.log(error);
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 3000);
        } finally {
            setPassword('')
            setPassword_confirmation('')
            setLoading(false);
        }
    }
    return (
        <div className=' md:w-[40vw] xl:w-[30vw] bg-white rounded-lg'>

            <form className='flex flex-col gap-4 m-6' >

                <div>
                    <p className='text-xl font-medium text-neutral-600 text-center mb-4 font-lufga'>Reset Password?</p>
                </div>

                <div>
                    <label
                        htmlFor='password'
                        className='block mb-2 text-sm font-medium text-gray-900 font-lufga'
                    >
                        New Password
                    </label>
                    <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'
                    >
                        <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={passwordState ? 'text' : 'password'}
                            name='password'
                            placeholder='••••••••'
                            className='bg-gray-50 focus:outline-none w-full'
                            required
                        />
                        {passwordState ? (
                            <IoEyeOutline size={20} onClick={handlePasswordToggle} />
                        ) : (
                            <FaRegEyeSlash size={20} onClick={handlePasswordToggle} />
                        )}
                    </div>
                </div>
                <div>
                    <label
                        className='block mb-2 text-sm font-medium text-gray-900 font-lufga '
                    >
                        Confirm New Password
                    </label>
                    <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'>
                        <input
                            value={password_confirmation}
                            onChange={(e) => setPassword_confirmation(e.target.value)}
                            type={confirm_passwordState ? 'text' : 'password'}
                            name='confirm_password'
                            placeholder='••••••••'
                            className='bg-gray-50 focus:outline-none w-full'
                            required
                        />
                        {confirm_passwordState ? (
                            <IoEyeOutline size={20} onClick={handleConfirmPasswordToggle} />
                        ) : (
                            <FaRegEyeSlash size={20} onClick={handleConfirmPasswordToggle} />
                        )}
                    </div>
                </div>
                {status ? <div>
                    <p className="bg-green-300 rounded-md shadow-md px-2 py-1 text-xs text-center font-lufga">{status}</p>
                </div> : error ? <div>
                    <p className="bg-red-300 rounded-md text-center shadow-md px-2 py-1 text-sm font-lufga">
                        {typeof error === 'string' ? error : (
                            Object.keys(error).map((key) => (
                                Array.isArray(error[key]) ? error[key][0] : error[key]
                            ))
                        )}
                    </p>
                </div> : null}
                <div className='flex justify-end'>
                    <Link to='/login'>
                        <p className='hover:underline cursor-pointer font-lufga text-sm text-blue-800'>Go to login</p>
                    </Link>

                </div>

                <div className='flex justify-end'>
                    <button
                        onClick={handleSubmit}

                        className='font-lufga text-white bg-blue-500 hover:opacity-90  focus:outline-none rounded-md shadow-md text-xs sm:text-sm px-5 py-2 sm:px-10 sm:py-3 text-center '
                    >

                        Reset Password
                    </button>
                </div>
                {loading ? (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <Hourglass
                        visible={true}
                        height="40"
                        width="40"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#306cce', '#72a1ed']}
                        loading={true}
                    />
                </div>) : null}

            </form>
        </div>
    );
}

export default ResetPassword;
