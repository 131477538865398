import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoCloseOutline, IoLogOutOutline } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import api from "../utils/api";
import { api_url } from "../config";
import { clearAuthData, logoutState } from "../Redux/AuthSlice";
import ngh from "../assets/ngh-white.png";
import cashsvg from "../assets/cash.svg"
import { MdPayment } from "react-icons/md";

const NavHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useSelector((state) => state.auth);
    const {token,email,name} = useSelector((state)=>state.auth)

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const isLoginPage = location.pathname === "/login";
    const isProductsPage = location.pathname === "/products";
    const isDashboardPage =
        location.pathname === "/subscriptions" || location.pathname === "/account";

    const handleLogout = async () => {
        try {
            dispatch(logoutState(true));

            await api.post(
                api_url + "/logout",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            dispatch(clearAuthData());

            // Manipulate the browser's history to prevent going back to protected pages
            window.history.pushState(null, "", "/login");
            window.onpopstate = () => {
                window.history.pushState(null, "", "/login");
            };

            dispatch(logoutState(false));
            setIsDropdownVisible(false)
            navigate("/login", { replace: true });
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    //   const handleClickOutside = (event) => {
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //       setIsDropdownVisible(false);
    //     }
    //   };

    //   useEffect(() => {
    //     document.addEventListener('click', handleClickOutside);
    //     return () => {
    //       document.removeEventListener('click', handleClickOutside);
    //     };
    //   }, []);

    const toggleDropdown = () => {
        setIsDropdownVisible((prevState) => !prevState);
    };

    return (
        <div className="flex justify-between items-center p-2 sm:py-2 sm:px-24 bg-transparent md:fixed w-full top-0 left-0 right-0 z-50">
            <div>
                <Link to={token ? "/subscriptions" : "/"}>
                    <span className="cursor-pointer">
                        <img src={ngh} className="h-10 sm:h-14 w-38" />
                    </span>
                </Link>
            </div>

            <div className="flex">
                {isLoginPage ||
                    (!token && (
                        <Link to="/login">
                            <button className="hover:underline text-blue-700 bg-white rounded-2xl px-5 py-1 text-xs sm:text-base sm:px-4 sm:py-2 shadow-md hover:opacity-95">
                                Login
                            </button>
                        </Link>
                    ))}
                {token && (
                    <div className="relative font-lufga" ref={dropdownRef}>
                        <div
                            onClick={toggleDropdown}
                            className={`rounded-full shadow-lg hover:opacity-80 cursor-pointer transition-transform duration-300 ${isDropdownVisible ? "scale-110" : ""
                                }`}
                        >
                            {isDropdownVisible ? (
                                <IoCloseOutline
                                    size={32}
                                    className="bg-white opacity-75 rounded-full p-2"
                                />
                            ) : (
                                <FaUserCircle size={32} color="#FFFFFF" />
                            )}
                        </div>
                        {isDropdownVisible && (
                            <div className="bg-slate-300/75 p-2 absolute flex flex-col gap-1 top-10 right-4 rounded shadow-md  min-w-[250px] z-50">
                                <div className=" bg-slate-100/80 p-2 flex flex-col gap-1 justify-center rounded">
                                   <Link to='/account'>
                                   <div 
                                   onClick={()=>{navigate('/accounts'); setIsDropdownVisible(false)}}
                                   className="cursor-pointer hover:bg-slate-100 hover:shadow-md rounded-md flex gap-2 items-center px-2 py-1">
                                        <FaUserCircle size={24} />
                                        <div className="flex flex-col">
                                            <p className="text-sm sm:text-sm">{name}</p>
                                            <p className="text-xs sm:text-xs">{email}</p>
                                        </div>
                                    </div>
                                   </Link>
                                        <div className="cursor-pointer hover:bg-slate-100 hover:shadow-md rounded-md flex gap-2 items-center px-2 py-1">
                                        <MdPayment size={18} />
                                        <button
                                        onClick={()=>{navigate('/subscriptions'); setIsDropdownVisible(false)}}
                                        className="text-xs sm:text-sm">Subscriptions</button>
                                        </div>
                                    
                                </div>
                                <div
                                    onClick={handleLogout}
                                    className="cursor-pointer hover:bg-slate-200/80 hover:shadow-md rounded-md flex gap-2 items-center px-2 py-1"
                                >
                                    <IoLogOutOutline size={22} color="#b91c1c"/>
                                    <p className="text-xs sm:text-sm text-red-700">Sign Out</p>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NavHeader;
